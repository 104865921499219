import React, { useContext, useEffect, useState } from "react";

import {
  Button,
  Col,
  DatePicker,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Slider,
  Spin,
  message,
} from "antd";

import { FaPlus } from "react-icons/fa";

import { LoadingOutlined } from "@ant-design/icons";
import "../form.css";
export default function AddFeed({ callAPI, setCallAPI }) {
  const [loading, setloading] = useState(false);
  const [gamesData, setGamesData] = useState();

  const savePlay = (row) => {
    const obj = {
      status: "review",
      link: gamesData.link,
      temp_id: gamesData.temp_id,
      title: gamesData.title,
      instruction: gamesData.instruction,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/feed`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setloading(false);
        if (response && response.isSuccess) {
            message.success("Added");
            setGamesData();
            setCallAPI(callAPI + 1);
        } else if (response.msgError === "Insufficient credits") {
        } else {
          message.error("something went wrong");
        }
      });
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "white",
      }}
      spin
    />
  );
  return (
    <div>
      <Button
        onClick={() => {
          setGamesData({
            ...gamesData,

            session: "single_session",

            access: "private",
            demographic_country: "united_states",
          });
        }}
        size="small"
        type="primary"
      >
        <FaPlus />
      </Button>
      <Modal
        width={600}
        open={gamesData !== undefined}
        onCancel={() => {
          setGamesData();
        }}
        className="no-f-no-h top-20-x  brand-modal"
      >
        {gamesData ? (
          <div>
            <h5 style={{ textAlign: "center" }}>Run Machine Vision</h5>

            <div className=" form-pf">
              <div className="game-show-p1">
                <div>
                  <div>
                    <div>
                      <label
                        style={{ margin: "20px 0px" }}
                        for="name"
                        class="label-pf"
                      >
                        <span class="title-pf">Name *</span>
                        <Input
                          placeholder="Name"
                          onChange={(e) => {
                            setGamesData({
                              ...gamesData,
                              title: e.target.value,
                            });
                          }}
                          value={gamesData.title}
                          style={{ width: "100%" }}
                          class="input-field-pf"
                        />
                      </label>

                      <div
                        className="together-fields-c"
                        style={{ margin: "20px 0px" }}
                      >
                        <label
                          style={{ width: "100%", marginRight: "10px" }}
                          for="name"
                          class="label-pf"
                        >
                          <span class="title-pf"> Link</span>

                          <Input
                            placeholder=" Link"
                            onChange={(e) => {
                              setGamesData({
                                ...gamesData,
                                link: e.target.value,
                              });
                            }}
                            class="input-field-pf"
                            value={gamesData.link}
                            size="large"
                          />
                        </label>
                      </div>
                      <label
                        style={{ margin: "20px 0px" }}
                        for="name"
                        class="label-pf"
                      >
                        <span class="title-pf">Temp ID *</span>
                        <Input
                          placeholder="Temp ID"
                          onChange={(e) => {
                            setGamesData({
                              ...gamesData,
                              temp_id: e.target.value,
                            });
                          }}
                          value={gamesData.temp_id}
                          style={{ width: "100%" }}
                          class="input-field-pf"
                        />
                      </label>
                      <div>
                        <label
                          style={{ margin: "20px 0px" }}
                          for="name"
                          class="label-pf"
                        >
                          <span class="title-pf">Instruction *</span>
                          <Input.TextArea
                            autoSize={{ minRows: 2, maxRows: 4 }}
                            placeholder="Instruction"
                            onChange={(e) => {
                              setGamesData({
                                ...gamesData,
                                instruction: e.target.value,
                              });
                            }}
                            value={gamesData.instruction}
                            style={{ width: "100%" }}
                            size="large"
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="space-b-btns">
                    <div />

                    <div className="save-btn-pl">
                      {loading ? (
                        <Spin indicator={antIcon} />
                      ) : gamesData.title &&
                        gamesData.title.length &&
                        gamesData.instruction &&
                        gamesData.instruction.length &&
                        gamesData.temp_id &&
                        gamesData.temp_id.length ? (
                        <div
                          onClick={() => {
                            setloading(true);
                            savePlay(gamesData);
                          }}
                          className="hover-card-b"
                          style={{
                            maxWidth: "300px",
                          }}
                        >
                          Save
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </Modal>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import moment from "moment";

function CountdownTimer({ date }) {
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  // Calculate time remaining
  function calculateTimeRemaining() {
    const targetDate = moment(date);
    const now = moment();
    const duration = moment.duration(targetDate.diff(now));
    return duration;
  }

  useEffect(() => {
    const timerID = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(timerID);
  }, [date]);
  const formatTimeComponent = (value) => {
    return String(value).padStart(2, "0");
  };
  return (
    <div className="time-counter-r">
      {(timeRemaining.seconds() && timeRemaining.seconds() < 0) ||
      (timeRemaining.minutes() && timeRemaining.minutes() < 0) ? (
        <span><b>00</b>:<b>00</b>:<b>00</b>:<b>00</b></span>
      ) : (
        <span>
          <b>{formatTimeComponent(timeRemaining.days())}</b>:
          <b>{formatTimeComponent(timeRemaining.hours())}</b>:
          <b>{formatTimeComponent(timeRemaining.minutes())}</b>:
          <b>{formatTimeComponent(timeRemaining.seconds())}</b>
        </span>
      )}
    </div>
  );
}

export default CountdownTimer;

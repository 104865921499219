import { Button, Modal } from "antd";
import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";

export default function PlayVideoModal({ row }) {
  const [show, setShow] = useState();
  const [feed, setFeed] = useState();
  const [play, setPlay] = useState(true);
  const [adjusted, setAdjusted] = useState(false);
  const playerRef = useRef(null);
  useEffect(() => {
    if (show && feed === undefined) {
      getFeed();
    }
  }, [show]);

  const getFeed = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`${process.env.REACT_APP_API}/feed/${row.feed_id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setFeed(data.feedData);
        }
      });
  };
  const adjustVideoTime = (seconds) => {
    // Ensure React Player reference is available
    if (playerRef.current && adjusted === false) {
      // Seek to the specified time in seconds
      playerRef.current.seekTo(seconds, "seconds");
      setAdjusted(true);
    }
  };
  return (
    <div>
      <Button
        onClick={() => {
          setAdjusted(false);
          setShow(row);
          setPlay(false);
        }}
        className="green-button"
      >
        Play
      </Button>
      <Modal
        width={600}
        open={show !== undefined}
        onCancel={() => {
          setPlay(false);

          setTimeout(() => {
            setShow();
            setFeed();
          }, 500);
        }}
        className="no-f-no-h top-20-x  brand-modal"
      >
        {feed ? (
          <div className="video-container radius-video">
            <ReactPlayer
              playing={play}
              onReady={() => adjustVideoTime(row.startTime)}
              play={play}
              url={feed.file_url}
              className=""
              controls
              ref={playerRef}
            />
          </div>
        ) : null}
      </Modal>
    </div>
  );
}

import {
  Badge,
  Button,
  Input,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Select,
  Spin,
  Table,
} from "antd";
import React, { useContext, useEffect, useState } from "react";

import { FaPlus } from "react-icons/fa";
import AddDblocks from "./AddDblocks";
const { Option } = Select;

export default function DblocksTable({ tRow }) {
  const [show, setShow] = useState(false);
  const [documentBlocks, setdocumentBlocks] = useState();

  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [callAPI, setCallAPI] = useState(1);
  const [isChange, setIsChange] = useState(false);
  const [dBlock, setdBlock] = useState();

  useEffect(() => {
    if (show) {
      setLoading(true);
      getdocumentBlocks();
    }
  }, [show, pageNo, callAPI]);

  const getdocumentBlocks = () => {
    const u = JSON.parse(window.localStorage.getItem("user"));

    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/block?page=${pageNo}&limit=6&sort_by=${-1}&document_id=${
        tRow._id
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data && data.isSuccess) {
          setdocumentBlocks(data.blockList);
          setTotalPages(data.totalPages);
        }
      });
  };

  const gamingColumns = [
    {
      title: "Parameter",
      dataIndex: "parameter",
      key: "parameter",
      width: 200,

      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          value={row.parameter}
          placeholder="parameter"
          onChange={(e) => {
            changeField("parameter", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ parameter: row.parameter }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Log Type",
      dataIndex: "log_type",
      key: "log_type",
      width: 200,

      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          value={row.log_type}
          placeholder="log_type"
          onChange={(e) => {
            changeField("log_type", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ log_type: row.log_type }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Content",
      dataIndex: "content",
      key: "content",
      width: 400,

      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          value={row.content}
          placeholder="Content"
          onChange={(e) => {
            changeField("content", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ content: row.content }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Feed Id",
      dataIndex: "feed_id",
      key: "feed_id",
      width: 200,

      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          value={row.feed_id}
          placeholder="feed_id"
          onChange={(e) => {
            changeField("feed_id", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ feed_id: row.feed_id }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    
    {
      title: "timestamp",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (text, row) => (
        <InputNumber
          value={row.timestamp}
          placeholder="timestamp"
          onChange={(e) => {
            changeField("timestamp", e, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ timestamp: row.timestamp }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 180,
      render: (text, r) => (
        <Select
          value={r.status}
          onChange={(v) => {
            changeField("status", v, r);
            saveChanges({ status: v }, r._id, true);
          }}
          className="black-select"
          style={{ width: "150px" }}
        >
          <Option value="error">
            <div className="drop-select-flex">
              <span className="text-drop-s"> Error</span>
            </div>
          </Option>
          <Option value="pending">
            <div className="drop-select-flex">
              <span className="text-drop-s"> Pending</span>
            </div>
          </Option>
          <Option value="progress">
            <div className="drop-select-flex">
              <span className="text-drop-s"> Progress</span>
            </div>
          </Option>
          <Option value="complete">
            <div className="drop-select-flex">
              <span className="text-drop-s"> Complete</span>
            </div>
          </Option>
        </Select>
      ),
    },
    {
      title: "",
      dataIndex: "001",
      key: "001",
      width: 100,
      render: (text, row) => (
        <Popconfirm
          title="Are you sure to mark complete this task?"
          onConfirm={() => deleteInvite(row._id)}
          okText="Yes"
          cancelText="No"
        >
          <Button className="red-button">Delete</Button>
        </Popconfirm>
      ),
    },
  ];

  const deleteInvite = (id) => {
    const filtereted = documentBlocks.filter((t) => t._id !== id);
    setdocumentBlocks(filtereted);
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`${process.env.REACT_APP_API}/block/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Deleted");
        } else {
          message.error("Something went wrong");
        }
        console.log(data);
      });
  };
  const changeField = (key, val, row) => {
    row[key] = val;
    const allRow = documentBlocks.map((m) => (m._id === row._id ? row : m));
    setdocumentBlocks(allRow);
  };
  const saveChanges = (row, id, save) => {
    if (isChange || save) {
      setIsChange(false);

      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify(row),
      };
      fetch(`https://embed.axv.ai/api/block/${id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data && data.isSuccess) {
            message.success("Updated");
          } else {
            message.error("Something went wrong");
          }
        });
    }
  };
  return (
    <>
      <Button type="primary">
        {" "}
        <FaPlus
          onClick={() => {
            setShow(true);
          }}
          size={18}
        />
      </Button>
      <div className="icon-circle-br-t"></div>

      <Modal
        width={1200}
        open={show}
        onCancel={() => {
          setShow();
          setPageNo(1);
          setdBlock();
          setTotalPages();
          setdocumentBlocks();
        }}
        className="no-buttons-modal black-modal top-20-x no-f-no-h"
      >
        <div style={{ minHeight: "300px" }}>
          <h3 style={{ textAlign: "center" }}>{tRow.title}</h3>
          <AddDblocks
            setdBlock={setdBlock}
            dBlock={dBlock}
            callAPI={callAPI}
            setCallAPI={setCallAPI}
            tRow={tRow}
          />
          {loading ? (
            <Spin />
          ) : (
            <Table
              className="scrol-tabel-c black-table pagination-white"
              columns={gamingColumns}
              dataSource={documentBlocks}
              pagination={{
                pageSize: 6,
                current: pageNo,
                total: totalPages * 6,
                onChange: (v) => {
                  setdocumentBlocks();
                  setPageNo(v);
                },
                size: "small",
                showSizeChanger: false,
              }}
            />
          )}
        </div>
      </Modal>
    </>
  );
}

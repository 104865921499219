import React, { useState } from "react";
import SearchContext from "./SearchContext";

const ContextState = (props) => {
  const [global, setGlobal] = useState({ tab: "feed" });

  return (
    <SearchContext.Provider value={{ global, setGlobal }}>
      {props.children}
    </SearchContext.Provider>
  );
};
export default ContextState;

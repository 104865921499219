import {
  Input,
  message,
  Image,
  Modal,
  Spin,
  Table,
  Tag,
  Button,
  InputNumber,
  Select,
  Popconfirm,
} from "antd";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import TagsGroup from "../../components/TagsGroup";
import FeedPopup from "./components/FeedPopup";
import { FaPause, FaPlay } from "react-icons/fa";
import PlayVideoModal from "./components/PlayVideoModal";

const { Option } = Select;
export default function Views() {
  const [list, setlist] = useState();
  const [totalPages, setTotalPages] = useState();
  const [reLoad, setreLoad] = useState();
  const [isChange, setIsChange] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [loadeing, setLoadeing] = useState(true);
  const [search, setsearch] = useState("");
  const [userId, setUserId] = useState("");
  const [streamFeed, setStreamFeed] = useState();
  const [user_id, setuser_id] = useState("");
  const [callAPI, setCallAPI] = useState(1);

  useEffect(() => {
    setLoadeing(true);
    getlist();
  }, [pageNo, userId, user_id, search, callAPI]);

  const getlist = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `${
        process.env.REACT_APP_API
      }/views?page=${pageNo}&limit=15&sort_by=${-1}&user_id=${user_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setlist(data.viewList);
          setTotalPages(data.totalPages);
        }

        setLoadeing(false);
      });
  };
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    const paddedHours = String(hours).padStart(2, "0");
    const paddedMinutes = String(minutes).padStart(2, "0");
    const paddedSeconds = String(secs).padStart(2, "0");

    return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
  };

  const TimeDisplay = ({ timeInSeconds }) => {
    const formattedTime = formatTime(timeInSeconds);
    const [hours, minutes, seconds] = formattedTime.split(":");

    return (
      <div>
        <b>{hours}</b>:<b>{minutes}</b>:<b>{seconds}</b>
      </div>
    );
  };
  const getFeedData = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`${process.env.REACT_APP_API}/feed/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setStreamFeed(data.feedData);
        } else {
          setStreamFeed();
        }
      });
  };
  const addRetry = (row) => {
    const obj = {
      thumbnail: row.thumbnail,
      file_id: row._id,
      topic: row.topic,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/vector/retry`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setreLoad();
        if (response && response.isSuccess) {
          message.success("Added");

          setCallAPI(callAPI + 1);
        } else if (response.msgError === "Insufficient credits") {
        } else {
          message.error("something went wrong");
        }
      });
  };
  const changeField = (key, val, row) => {
    row[key] = val;
    const allRow = list.map((m) => (m._id === row._id ? row : m));
    setlist(allRow);
  };
  const deleteFile = (id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`${process.env.REACT_APP_API}/vector/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          const filtereted = list.filter((t) => t._id !== id);
          setlist(filtereted);
          message.success("Deleted");
        } else {
          message.error("Something went wrong");
        }
        console.log(data);
      });
  };

  const columns = [
    {
      title: "",
      dataIndex: "010",
      key: "010",
      width:60,
      render: (text, row) => {
        return (
          <>
          <PlayVideoModal row={row}/>
            
          </>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "business_name",
      key: "business_name",
      render: (text, row) => {
        return (
          <>
            <b>{text}</b>
          </>
        );
      },
    },
    {
      title: "Categroy",
      dataIndex: "category",
      key: "category",
      render: (text, row) => {
        return (
          <>
            <b>
              {text === "start" ? (
                <FaPlay color="lime" />
              ) : (
                <FaPause color="red" />
              )}
            </b>
          </>
        );
      },
    },
    {
      title: "StartTime",
      dataIndex: "startTime",
      key: "startTime",
      render: (text, row) => {
        return (
          <>
            <TimeDisplay timeInSeconds={text} />
          </>
        );
      },
    },
    {
      title: "EndTime",
      dataIndex: "endTime",
      key: "endTime",
      render: (text, row) => {
        return (
          <>
            <TimeDisplay timeInSeconds={text} />
          </>
        );
      },
    },

    {
      title: "",
      dataIndex: "001",
      key: "001",
      render: (text, row) => (
        <Popconfirm
          title="Are you sure to mark complete this task?"
          onConfirm={() => deleteFile(row._id)}
          okText="Yes"
          cancelText="No"
        >
          <Button className="red-button">Delete</Button>
        </Popconfirm>
      ),
    },
  ];
  return (
    <div className="roleplay-main-c">
      {streamFeed ? (
        <FeedPopup
          setStreamFeed={setStreamFeed}
          streamFeed={streamFeed}
          row={streamFeed}
        />
      ) : null}
      <div className="table-top-nav">
        {" "}
        <div
          style={{ justifyContent: "space-between" }}
          className="form-add--theme"
        >
          <div />
          <div>
            <Input
              allowClear
              onChange={(e) => {
                setuser_id(e.target.value);
                if (e.target.value.trim().length) {
                  getFeedData(e.target.value);
                } else {
                  setStreamFeed();
                }
                setlist();
                setPageNo(1);
                setCallAPI(callAPI + 1);
              }}
              value={user_id}
              placeholder="Stream ID"
            />
          </div>
          <div />
        </div>
      </div>
      {loadeing ? <Spin /> : null}
      {list ? (
        <Table
          className="scrol-tabel-c black-table pagination-white"
          pagination={{
            pageSize: 15,
            current: pageNo,
            total: totalPages * 15,
            onChange: (v) => {
              setlist();
              setPageNo(v);
            },
            size: "small",
            showSizeChanger: false,
          }}
          columns={columns}
          dataSource={list}
        />
      ) : null}
    </div>
  );
}

import React, { useState } from "react";
import { Modal, Input, Button, message } from "antd";

const Passcode = ({ user, setUser }) => {
  const [passcode, setPasscode] = useState("");
  const [all, setAll] = useState(["PAUL_00", "RAHE_00", "SH_09"]);

  const handleSavePasscode = () => {
    if (passcode.trim() !== "") {
      let isUser = all.filter((p) => p === passcode);
      if (isUser && isUser.length) {
        localStorage.setItem("passcode", passcode);
        setUser(true);
      } else {
        message.warning("passcode not correct");
      }
    }
  };

  return (
    <Modal
      open={!user}
      footer={[
        <Button key="cancel" onClick={() => setUser(false)}>
          Cancel
        </Button>,
        <Button key="save" type="primary" onClick={handleSavePasscode}>
          Save
        </Button>,
      ]}
      width={300}
    >
      <Input
        style={{ marginTop: "30px" }}
        placeholder="Enter passcode"
        value={passcode}
        onChange={(e) => setPasscode(e.target.value)}
      />
    </Modal>
  );
};

export default Passcode;

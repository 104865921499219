import { useEffect, useState } from "react";
import "./App.css";
import ContextState from "./Context/Search/ContextState";
import Index from "./pages/rolePlay/Index";
import Passcode from "./components/Passcode";

function App() {
  const [user, setUser] = useState(false);

  useEffect(() => {
    const passcode = localStorage.getItem("passcode");

    if (passcode) {
      setUser(true);
    } else {
      setUser(false);
    }
  }, []);
  return (
    <div>
      {user ? (
        <ContextState>
          <Index />
        </ContextState>
      ) : <Passcode user={user} setUser={setUser}/>}
    </div>
  );
}

export default App;

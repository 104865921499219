import React, { useRef, useState } from "react";
import ReactPlayer from "react-player";
import TagsGroup from "../../../components/TagsGroup";
import { Button, Input, message } from "antd";
import { FaInfo, FaMinus } from "react-icons/fa";
import { useEffect } from "react";

export default function FeedPopup({ row, setStreamFeed, streamFeed, cTime }) {
  const [inputVidew, setInputVidew] = useState(false);
  const [show, setShow] = useState(true);
  const playerRef = useRef(null);
  useEffect(() => {
    if (cTime !== undefined) {
      adjustVideoTime(cTime);
    }
  }, [cTime]);
  const adjustVideoTime = (seconds) => {
    // Ensure React Player reference is available
    if (playerRef.current) {
      // Seek to the specified time in seconds
      playerRef.current.seekTo(seconds, "seconds");
    }
  };

  const saveChanges = (row, id, save) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(row),
    };
    fetch(`${process.env.REACT_APP_API}/feed/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isPatched) {
          message.success("Updated");
        } else {
          message.error("Something went wrong");
        }
      });
  };
  return show ? (
    <div class="unique-popup">
      <button
        onClick={() => {
          setShow(false);
          setInputVidew(false);
        }}
        class="unique-close-btn"
      >
        <FaMinus />
      </button>{" "}
      <div className="video-container radius-video">
        <ReactPlayer ref={playerRef} url={row.file_url} className="" controls />
      </div>
      <div className="topics-game-c">
        <div>
          <h4>Topics</h4>
          <TagsGroup
            tags={row.topics ? row.topics : []}
            color="#004aa3"
            onTagChange={(v) => {
              saveChanges({ topics: v }, row._id, true);
              setStreamFeed({ ...streamFeed, topics: v });
            }}
          />
        </div>
        {inputVidew ? null : (
          <Button
            onClick={() => {
              setInputVidew(true);
            }}
          >
            Gaming
          </Button>
        )}
      </div>
      {inputVidew ? (
        <div className="input-bx-item12">
          <div className="input-bx-item">
            <h6>Game Design</h6>
            <Input.TextArea
              autoSize={{ minRows: 1, maxRows: 3 }}
              placeholder="Game Design"
              value={row.game_design}
              onChange={(v) => {
                setStreamFeed({
                  ...streamFeed,
                  game_design: v.target.value,
                });
              }}
              onBlur={(v) => {
                saveChanges({ game_design: v.target.value }, row._id, true);
              }}
            />
          </div>
          <div className="input-bx-item">
            <h6>Accessibility</h6>
            <Input.TextArea
              autoSize={{ minRows: 1, maxRows: 3 }}
              placeholder="Accessibility"
              value={row.accessibility}
              onChange={(v) => {
                setStreamFeed({
                  ...streamFeed,
                  accessibility: v.target.value,
                });
              }}
              onBlur={(v) => {
                saveChanges({ accessibility: v.target.value }, row._id, true);
              }}
            />
          </div>
          <div className="input-bx-item">
            <h6>Art Style</h6>
            <Input.TextArea
              autoSize={{ minRows: 1, maxRows: 3 }}
              placeholder="Art Style"
              value={row.art_style}
              onChange={(v) => {
                setStreamFeed({
                  ...streamFeed,
                  art_style: v.target.value,
                });
              }}
              onBlur={(v) => {
                saveChanges({ art_style: v.target.value }, row._id, true);
              }}
            />
          </div>
          <div className="input-bx-item">
            <h6>Fun</h6>
            <Input.TextArea
              autoSize={{ minRows: 1, maxRows: 3 }}
              placeholder="Fun"
              value={row.fun}
              onChange={(v) => {
                setStreamFeed({
                  ...streamFeed,
                  fun: v.target.value,
                });
              }}
              onBlur={(v) => {
                saveChanges({ fun: v.target.value }, row._id, true);
              }}
            />
          </div>
          <div className="input-bx-item">
            <h6>Narrative Story</h6>
            <Input.TextArea
              autoSize={{ minRows: 1, maxRows: 3 }}
              placeholder="Narrative Story"
              value={row.narrative_story}
              onChange={(v) => {
                setStreamFeed({
                  ...streamFeed,
                  narrative_story: v.target.value,
                });
              }}
              onBlur={(v) => {
                saveChanges({ narrative_story: v.target.value }, row._id, true);
              }}
            />
          </div>
          <div className="input-bx-item">
            <h6>Sound Music</h6>
            <Input.TextArea
              autoSize={{ minRows: 1, maxRows: 3 }}
              placeholder="Sound Music"
              value={row.sound_music}
              onChange={(v) => {
                setStreamFeed({
                  ...streamFeed,
                  sound_music: v.target.value,
                });
              }}
              onBlur={(v) => {
                saveChanges({ sound_music: v.target.value }, row._id, true);
              }}
            />
          </div>
          <div className="input-bx-item">
            <h6>Technical</h6>
            <Input.TextArea
              autoSize={{ minRows: 1, maxRows: 3 }}
              placeholder="Technical"
              value={row.technical}
              onChange={(v) => {
                setStreamFeed({
                  ...streamFeed,
                  technical: v.target.value,
                });
              }}
              onBlur={(v) => {
                saveChanges({ technical: v.target.value }, row._id, true);
              }}
            />
          </div>
          <div className="input-bx-item">
            <h6>User Experience</h6>
            <Input.TextArea
              autoSize={{ minRows: 1, maxRows: 3 }}
              placeholder="User Experience"
              value={row.user_experience}
              onChange={(v) => {
                setStreamFeed({
                  ...streamFeed,
                  user_experience: v.target.value,
                });
              }}
              onBlur={(v) => {
                saveChanges({ user_experience: v.target.value }, row._id, true);
              }}
            />
          </div>
        </div>
      ) : null}
    </div>
  ) : (
    <div
      onClick={() => {
        setShow(true);
      }}
      class="unique-popup open-defailt-cb"
    >
      <FaInfo />
      Info
    </div>
  );
}

import {
  Input,
  message,
  Modal,
  Spin,
  Table,
  Tag,
  Button,
  InputNumber,
  Select,
  Popconfirm,
  Switch,
} from "antd";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";

import { AiOutlinePlus } from "react-icons/ai";
import { MdClose, MdPlusOne } from "react-icons/md";

import SearchContext from "../../Context/Search/SearchContext";
import moment from "moment";
import AddOrganization from "./components/AddOrganization";

export default function Organization() {
  const [callAPI, setCallAPI] = useState(1);
  const [Org, setOrg] = useState();
  const [totalPages, setTotalPages] = useState();
  const [dDelete, displayDelete] = useState();
  const [isChange, setIsChange] = useState(false);
  const [isChangeG, setIsChangeG] = useState(false);
  const [search, setSearch] = useState("");

  const [pageNo, setPageNo] = useState(1);
  const [loadeing, setLoadeing] = useState(true);

  const [Description, setDescription] = useState();
  const [DescriptionId, setDescriptionId] = useState();

  useEffect(() => {
    if (search.length === 0) {
      setLoadeing(true);
      getOrg();
    }
  }, [pageNo, callAPI]);

  const getOrg = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `${
        process.env.REACT_APP_API
      }/organization?page=${pageNo}&limit=15&sort_by=${-1}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setOrg(data.organizationList);
          setTotalPages(data.totalPages);
        }

        setLoadeing(false);
        console.log(data);
      });
  };

  const saveChanges = (row, id, save) => {
    if (isChange || save) {
      setIsChange(false);
      closeDescription();
      const keys = Object.keys(row);
      const formData = new FormData();
      formData.append([keys[0]], row[keys[0]]);
      const requestOptions = {
        method: "PUT",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
        body: formData,
      };
      fetch(`${process.env.REACT_APP_API}/organization/${id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data && data.isSuccess) {
            message.success("Updated");
          } else {
            message.error("Something went wrong");
          }
        });
    }
  };

  const deleteInvite = (id) => {
    const filtereted = Org.filter((t) => t._id !== id);
    setOrg(filtereted);
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`${process.env.REACT_APP_API}/organization/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Deleted");
        } else {
          message.error("Something went wrong");
        }
        console.log(data);
      });
  };

  const changeField = (key, val, row) => {
    row[key] = val;
    const allRow = Org.map((m) => (m._id === row._id ? row : m));
    setOrg(allRow);
  };

  const closeDescription = () => {
    setDescription();
    setDescriptionId();
    setIsChangeG(false);
  };

  const columns = [
    {
      title: "Business Name",
      dataIndex: "business_name",
      key: "business_name",
      width: 150,
      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          value={text}
          placeholder="business_name"
          onChange={(e) => {
            changeField("business_name", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ business_name: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    // {
    //   title: "Root Name",
    //   dataIndex: "root_name",
    //   key: "root_name",
    //   width: 150,
    //   render: (text, row) => (
    //     <Input.TextArea
    //       autoSize={{ minRows: 1, maxRows: 4 }}
    //       value={text}
    //       placeholder="root_name"
    //       onChange={(e) => {
    //         changeField("root_name", e.target.value, row);
    //         setIsChange(true);
    //       }}
    //       onBlur={() => saveChanges({ root_name: text }, row._id)}
    //       className="hidden-textarea"
    //     />
    //   ),
    // },
    {
      title: "Root Email",
      dataIndex: "root_email",
      key: "root_email",
      width: 150,
      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          value={text}
          placeholder="Root Email"
          onChange={(e) => {
            changeField("root_email", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ root_email: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "source",
      dataIndex: "source",
      key: "source",
      width: 150,
      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          value={text}
          placeholder="source"
          onChange={(e) => {
            changeField("source", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ source: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Source Link",
      dataIndex: "source_link",
      key: "source_link",
      width: 150,
      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          value={text}
          placeholder="source link"
          onChange={(e) => {
            changeField("source_link", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ source_link: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Reddit",
      dataIndex: "reddit",
      key: "reddit",
      width: 150,
      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          value={text}
          placeholder="reddit"
          onChange={(e) => {
            changeField("reddit", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ reddit: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Discord",
      dataIndex: "discord",
      key: "discord",
      width: 150,
      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          value={text}
          placeholder="discord"
          onChange={(e) => {
            changeField("discord", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ discord: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Game Referal",
      dataIndex: "game_referal",
      key: "game_referal",
      width: 150,
      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          value={text}
          placeholder="game_referal"
          onChange={(e) => {
            changeField("game_referal", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ game_referal: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Subscription",
      dataIndex: "subscription",
      key: "subscription",
      width: 150,
      render: (text, row) => (
        <Select
          value={text}
          placeholder="subscription"
          onChange={(value) => {
            changeField("subscription", value, row);

            saveChanges({ subscription: value }, row._id, true);
          }}
          style={{ width: "120px" }}
          options={[
            {
              value: "business",
              label: "Business",
            },

            {
              value: "community",
              label: "Community",
            },

            {
              value: "individual",
              label: "Individual",
            },
            {
              value: "startup",
              label: "Startup",
            },

            {
              value: "pro",
              label: "Pro",
            },
            {
              value: "restricted",
              label: "Restricted",
            },
          ]}
        />
      ),
    },
    {
      title: "Agreement",
      dataIndex: "user_agreement",
      key: "user_agreement",
      width: 100,
      render: (text, row) => (
        <Switch
          checked={text}
          placeholder="user agreement"
          onChange={(e) => {
            changeField("user_agreement", e, row);
            saveChanges({ user_agreement: e }, row._id, true);
          }}
        />
      ),
    },

    {
      title: "P-Credits",
      dataIndex: "platform_credits",
      key: "platform_credits",
      width: 100,
      render: (text, row) => (
        <InputNumber
          value={text}
          placeholder="platform_credits"
          onChange={(e) => {
            changeField("platform_credits", e, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ platform_credits: text }, row._id)}
          className="hidden-textarea"
          style={{ width: "100px" }}
        />
      ),
    },

    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
      width: 150,
      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          value={text}
          placeholder="Subscription"
          onChange={(e) => {
            changeField("_id", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ _id: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 100,
      render: (text, row) => <span>{moment(text).format("MMM Do YYYY ")}</span>,
    },

    {
      title: "",
      dataIndex: "001",
      key: "001",
      render: (text, row) => (
        <Popconfirm
          title="Are you sure to mark complete this task?"
          onConfirm={() => deleteInvite(row._id)}
          okText="Yes"
          cancelText="No"
        >
          <Button size="small" className="red-button">
            D
          </Button>
        </Popconfirm>
      ),
    },
  ];
  const getroleOrgSearch = (s) => {
    const obj = {
      query: s,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`${process.env.REACT_APP_API}/organization/search`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setOrg(data);
        }
      });
  };
  return (
    <div className="roleplay-main-c">
      <div className="table-top-nav">
        <div
          style={{ justifyContent: "space-between" }}
          className="form-add--theme"
        >
          <AddOrganization callAPI={callAPI} setCallAPI={setCallAPI} />
          <div>
            <Input
              onChange={(e) => {
                setSearch(e.target.value);
                if (e.target.value.length) {
                  setOrg();
                  getroleOrgSearch(e.target.value);
                } else {
                  setOrg();
                  setPageNo(1);
                  setCallAPI(callAPI + 1);
                }
              }}
              value={search}
              placeholder="Search Title"
            />
          </div>
          <div />
        </div>
      </div>
      {loadeing ? <Spin /> : null}
      {Org ? (
        <Table
          pagination={{
            pageSize: 15,
            current: pageNo,
            total: totalPages * 15,
            onChange: (v) => {
              setOrg();
              setPageNo(v);
            },
            size: "small",
            showSizeChanger: false,
          }}
          className="scrol-tabel-c black-table pagination-white"
          columns={columns}
          dataSource={Org}
        />
      ) : null}
    </div>
  );
}

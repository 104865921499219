import {
  Input,
  message,
  Image,
  Modal,
  Spin,
  Table,
  Tag,
  Button,
  InputNumber,
  Select,
  Popconfirm,
} from "antd";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import TagsGroup from "../../components/TagsGroup";
import FeedPopup from "./components/FeedPopup";

const { Option } = Select;
export default function VectorData() {
  const [list, setlist] = useState();
  const [totalPages, setTotalPages] = useState();
  const [reLoad, setreLoad] = useState();
  const [isChange, setIsChange] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [loadeing, setLoadeing] = useState(true);
  const [search, setsearch] = useState("");
  const [userId, setUserId] = useState("");
  const [streamFeed, setStreamFeed] = useState();
  const [stream_id, setStream_id] = useState("");
  const [callAPI, setCallAPI] = useState(1);

  useEffect(() => {
    setLoadeing(true);
    getlist();
  }, [pageNo, userId, stream_id, search, callAPI]);

  const getlist = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `${
        process.env.REACT_APP_API
      }/vector?page=${pageNo}&limit=15&sort_by=${-1}&stream_id=${stream_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setlist(data.vectorList);
          setTotalPages(data.totalPages);
        }

        setLoadeing(false);
      });
  };
  const saveChanges = (row, id, save) => {
    if (isChange || save) {
      setIsChange(false);

      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify(row),
      };
      fetch(`${process.env.REACT_APP_API}/vector/${id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data && data.isSuccess) {
            message.success("Updated");
          } else {
            message.error("Something went wrong");
          }
        });
    }
  };
  const getFeedData = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`${process.env.REACT_APP_API}/feed/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setStreamFeed(data.feedData);
        }else{
          setStreamFeed()
        }
      });
  };
  const addRetry = (row) => {
    const obj = {
      thumbnail: row.thumbnail,
      file_id: row._id,
      topic: row.topic,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/vector/retry`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setreLoad();
        if (response && response.isSuccess) {
          message.success("Added");

          setCallAPI(callAPI + 1);
        } else if (response.msgError === "Insufficient credits") {
        } else {
          message.error("something went wrong");
        }
      });
  };
  const changeField = (key, val, row) => {
    row[key] = val;
    const allRow = list.map((m) => (m._id === row._id ? row : m));
    setlist(allRow);
  };
  const deleteFile = (id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`${process.env.REACT_APP_API}/vector/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          const filtereted = list.filter((t) => t._id !== id);
          setlist(filtereted);
          message.success("Deleted");
        } else {
          message.error("Something went wrong");
        }
        console.log(data);
      });
  };

  const columns = [
    {
      title: "Url",
      dataIndex: "thumbnail",
      key: "thumbnail",
      render: (text, row) => {
        return (
          <>
            <Image width={100} className="thumbnail-img" src={text} alt="" />
          </>
        );
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      
      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          value={text}
          placeholder="title"
          onChange={(e) => {
            changeField("title", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ title: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Content",
      dataIndex: "content",
      key: "content",
      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 6 }}
          value={text}
          placeholder="content"
          onChange={(e) => {
            changeField("content", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ content: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (text, row) => (
        <InputNumber
          autoSize={{ minRows: 1, maxRows: 4 }}
          value={text}
          placeholder="timestamp"
          onChange={(e) => {
            changeField("timestamp", e, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ timestamp: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Stream ID",
      dataIndex: "stream_id",
      key: "stream_id",
      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          value={text}
          placeholder="stream_id"
          onChange={(e) => {
            changeField("stream_id", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ stream_id: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          value={text}
          placeholder="description"
          onChange={(e) => {
            changeField("description", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ description: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      
      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          value={text}
          placeholder="comment"
          onChange={(e) => {
            changeField("comment", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ comment: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Schema",
      dataIndex: "schema",
      key: "schema",
      
      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 2 }}
          value={text}
          placeholder="schema"
          onChange={(e) => {
            changeField("schema", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ schema: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Topic",
      dataIndex: "topic",
      key: "topic",
   
      render: (text, row) => (
        <Select
          value={text}
          placeholder="Topic"
          onChange={(value) => {
            changeField("topic", value, row);

            saveChanges({ topic: value }, row._id, true);
          }}
          style={{ width: "200px" }}
          options={[
            {
              value: "Game Design",
              label: "Game Design",
            },
            {
              value: "Onboarding",
              label: "Onboarding",
            },
            {
              value: "Progression",
              label: "Progression",
            },
            {
              value: "Engagement",
              label: "Engagement",
            },
            {
              value: "Balance",
              label: "Balance",
            },
            {
              value: "Mechanics",
              label: "Mechanics",
            },
          
            {
              value: "Replayability",
              label: "Replayability",
            },
            {
              value: "Enjoyment",
              label: "Enjoyment",
            },
           
           
            {
              value: "Art Style",
              label: "Art Style",
            },
            {
              value: "Readability",
              label: "Readability",
            },
            {
              value: "Performance",
              label: "Performance",
            },
            {
              value: "Cohesiveness",
              label: "Cohesiveness",
            },
            {
              value: "Emotional Impact",
              label: "Emotional Impact",
            },

            {
              value: "Narrative & Story",
              label: "Narrative & Story",
            },
            {
              value: "Dialogue Quality",
              label: "Dialogue Quality",
            },
            {
              value: "Character Appeal",
              label: "Character Appeal",
            },
            {
              value: "Narrative Pacing",
              label: "Narrative Pacing",
            },


            {
              value: "Accessibility",
              label: "Accessibility",
            },
            {
              value: "Captions",
              label: "Captions",
            },
            {
              value: "Colorblindness",
              label: "Colorblindness",
            },
            {
              value: "Difficulty",
              label: "Difficulty",
            },
            {
              value: "Control",
              label: "Control",
            },

            {
              value: "Sound",
              label: "Sound",
            },

            {
              value: "Technical",
              label: "Technical",
            },

            {
              value: "Hardware",
              label: "Hardware",
            },

            {
              value: "Compatibility",
              label: "Compatibility",
            },

            {
              value: "Bugs",
              label: "Bugs",
            },

            {
              value: "Framerate",
              label: "Framerate",
            },




            {
              value: "User Experience",
              label: "User Experience",
            },

            {
              value: "Buttons",
              label: "Buttons",
            },

            {
              value: "Navigation",
              label: "Navigation",
            },

           
           
            
          ]}
        />
      ),
    },
    {
      title: "Subtopic",
      dataIndex: "subtopic",
      key: "subtopic",
   
      render: (text, row) => (
        <Select
          value={text}
          placeholder="Subtopic"
          onChange={(value) => {
            changeField("subtopic", value, row);

            saveChanges({ subtopic: value }, row._id, true);
          }}
          style={{ width: "200px" }}
          options={[
            {
              value: "Game Menu - Start",
              label: "Game Menu - Start",
            },
            {
              value: "Game Menu - Control Configuration",
              label: "Game Menu - Control Configuration",
            },
            {
              value: "Game Menu - Loading Page",
              label: "Game Menu - Loading Page",
            },
            {
              value: "Game Menu - Equipment Items",
              label: "Game Menu - Equipment Items",
            },
            {
              value: "Game Mechanics - Movement",
              label: "Game Mechanics - Movement",
            },
            {
              value: "Game Mechanics - Physics",
              label: "Game Mechanics - Physics ",
            },
            {
              value: "Fun - Replayability",
              label: "Fun - Replayability",
            },
            {
              value: "Fun - Enjoyment",
              label: "Fun - Enjoyment",
            },
            {
              value: "Game Art Style - Overall Style",
              label: "Game Art Style - Overall Style",
            },

            {
              value: "Game Art Style - Color Palette",
              label: "Game Art Style - Color Palette",
            },
            {
              value: "Game Art Style - Art Assets",
              label: "Game Art Style - Art Assets",
            },
            {
              value: "Game Art Style - Cohesiveness",
              label: "Game Art Style - Cohesiveness",
            },
            {
              value: "Game Art Style - Character Design",
              label: "Game Art Style - Character Design",
            },
          
            {
              value: "Story - Dialogue Quality",
              label: "Story - Dialogue Quality",
            },
            {
              value: "Story - Character Appeal",
              label: "Story - Character Appeal",
            },
            {
              value: "Story - Pacing",
              label: "Story - Pacing",
            },

            {
              value: "Accessibility - Captions",
              label: "Accessibility - Captions",
            },

            {
              value: "Accessibility - Colorblindness",
              label: "Accessibility - Colorblindness",
            },
               {
              value: "Accessibility - Readability",
              label: "Accessibility - Readability",
            },
            {
              value: "Accessibility - Difficulty",
              label: "Accessibility - Difficulty",
            },
            {
              value: "Accessibility - Control",
              label: "Accessibility - Control",
            },

            {
              value: "User Experience - Buttons",
              label: "User Experience - Buttons",
            },

            {
              value: "Technical - Hardware",
              label: "Technical - Hardware",
            },

            {
              value: "Techical - Compatibility",
              label: "Technical - Compatibility",
            },

            {
              value: "Technical - Bugs",
              label: "Technical - Bugs",
            },
            {
              value: "Technical - Framerate",
              label: "Technical - Framerate",
            },
            {
              value: "Technical - Loading Times",
              label: "Technical - Loading Times",
            },

            {
              value: "User Experience - Menu Navigation",
              label: "User Experience - Menu Navigation",
            },

          
            {
              value: "User Experience - Onboarding",
              label: "User Experience - Onboarding",
            },

            {
              value: "User Experience - Intuitiveness",
              label: "User Experience - Intuitiveness",
            },

            {
              value: "Flow and Immersion",
              label: "Flow and Immersion",
            },
          ]}
        />
      ),
    },
   
    {
      title: "Mood",
      dataIndex: "mood",
      key: "mood",
   
      render: (text, row) => (
        <Select
          value={text}
          placeholder="Mood"
          onChange={(value) => {
            changeField("mood", value, row);

            saveChanges({ mood: value }, row._id, true);
          }}
          style={{ width: "100px" }}
          options={[
            {
              value: "Positive",
              label: "Positive",
            },
          
            {
              value: "Negative",
              label: "Negative",
            },
           
            {
              value: "Positive and Negative",
              label: "Positive and Negative",
            }
            
          ]}
        />
      ),
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      render: (text, row) => (
        <TagsGroup
          tags={text ? text : []}
          color="#004aa3"
          onTagChange={(v) => {
            changeField("tags", v, row);
            saveChanges({ tags: v }, row._id, true);
          }}
        />
      ),
    },
    {
      title: "",
      dataIndex: "001",
      key: "001",
      render: (text, row) =>
        reLoad === row._id ? (
          <Spin />
        ) : (
          <Popconfirm
            title="Are you sure to Retry?"
            onConfirm={() => {
              setreLoad(row._id);
              addRetry(row);
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button className="yellow-button">Retry</Button>
          </Popconfirm>
        ),
    },

    {
      title: "",
      dataIndex: "001",
      key: "001",
      render: (text, row) => (
        <Popconfirm
          title="Are you sure to mark complete this task?"
          onConfirm={() => deleteFile(row._id)}
          okText="Yes"
          cancelText="No"
        >
          <Button className="red-button">Delete</Button>
        </Popconfirm>
      ),
    },
  ];
  return (
    <div className="roleplay-main-c">
      {streamFeed ? (
        <FeedPopup
          setStreamFeed={setStreamFeed}
          streamFeed={streamFeed}
          row={streamFeed}
        />
      ) : null}
      <div className="table-top-nav">
        {" "}
        <div
          style={{ justifyContent: "space-between" }}
          className="form-add--theme"
        >
          <div />
          <div>
            <Input
              allowClear
              onChange={(e) => {
                setStream_id(e.target.value);
                if (e.target.value.trim().length) {
                  getFeedData(e.target.value);
                }else{
                  setStreamFeed()
                }
                setlist();
                setPageNo(1);
                setCallAPI(callAPI + 1);
              }}
              value={stream_id}
              placeholder="Stream ID"
            />
          </div>
          <div />
        </div>
      </div>
      {loadeing ? <Spin /> : null}
      {list ? (
        <Table
          className="scrol-tabel-c black-table pagination-white"
          pagination={{
            pageSize: 15,
            current: pageNo,
            total: totalPages * 15,
            onChange: (v) => {
              setlist();
              setPageNo(v);
            },
            size: "small",
            showSizeChanger: false,
          }}
          columns={columns}
          dataSource={list}
        />
      ) : null}
    </div>
  );
}

import React, { useState } from "react";
import {
  Button,
  Input,
  InputNumber,
  Modal,
  Spin,
  Upload,
  message,
  notification,
} from "antd";

export default function UploadScreenShoot({ row }) {
  const [UploadedFile, setUplaoded] = useState([]);
  const [show, setShow] = useState();
  const [stream_id, setstream_id] = useState();
  const [timestamp, settimestamp] = useState();
  const [loading, setLoading] = useState(false);
  const props = {
    name: "file",
    status: "done",
    listType: "picture-circle",
    // action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },

    fileList: UploadedFile,
    onChange(info) {
      setUplaoded(info.fileList);
    },
  };

  const connect = (f) => {
    const formData = new FormData();
    formData.append("file", f.originFileObj);

    formData.append("stream_id", row._id);
    formData.append("timestamp", timestamp);
    const requestOptions = {
      method: "POST",
      body: formData,
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/vector/upload/thumbnail`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data.isSuccess) {
          setShow();
          setstream_id();
          settimestamp();
          setUplaoded([]);
          message.success("Added");
        } else {
          message.error("Error");
        }
      });
  };
  return (
    <div>
      <Button onClick={() => setShow(row)} size="large" type="primary">
        Upload
      </Button>
      <Modal
        width={600}
        open={show !== undefined}
        onCancel={() => {
          setShow();
        }}
        className="no-f-no-h top-20-x  brand-modal"
      >
        <div className="screen-shot-form">
          <div>
            <div>
              <h6>Screen Shoot</h6>
              <Upload
                // accept=""
                maxCount={1}
                {...props}
                style={{ position: "relative" }}
              >
                Upload
              </Upload>
            </div>{" "}
            <div>
              <h6>TimeStamp</h6>
              <InputNumber
                size="large"
                placeholder="00 Seconds"
                value={timestamp}
                onChange={(e) => settimestamp(e)}
                style={{ width: "120px" }}
              />
            </div>
            <div className="flex-end">
              {UploadedFile && UploadedFile.length ? (
                loading ? (
                  <Spin />
                ) : (
                  <Button
                    size="large"
                    type="primary"
                    onClick={() => {
                      setLoading(true);
                      connect(UploadedFile[0]);
                    }}
                  >
                    Save
                  </Button>
                )
              ) : null}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

import { Alert, Button, Popconfirm, Select, Table, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../Context/Search/SearchContext";
import CountdownTimer from "./components/CountdownTimer";
import Paragraph from "antd/es/typography/Paragraph";
import moment from "moment";

export default function Kernal() {
  const { global, setGlobal } = useContext(SearchContext);
  const [rolePlay, setrolePlay] = useState();
  const [live, setLive] = useState("pending");
  const [agent_id, setAgentId] = useState("");
  const [isChange, setIsChange] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [callAPI, setCallAPI] = useState(1);

  useEffect(() => {
    getrolePlay();
  }, [global.callKernal, pageNo, callAPI]);

  const getrolePlay = () => {
    const passcode = localStorage.getItem("passcode");

    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `${
        process.env.REACT_APP_API
      }/feed?page=${pageNo}&limit=15&sort_by=${-1}&live=${live}&agent_id=${agent_id}&status=demo`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setrolePlay(data.feedList);
          setTotalPages(data.totalPages);
        }
      });
  };
  const saveChanges = (row, id, save) => {
    if (isChange || save) {
      setIsChange(false);

      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify(row),
      };
      fetch(`${process.env.REACT_APP_API}/feed/${id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data && data.isPatched) {
            message.success("Updated");
          } else {
            message.error("Something went wrong");
          }
        });
    }
  };

  const changeField = (key, val, row) => {
    row[key] = val;
    const allRow = rolePlay.map((m) => (m._id === row._id ? row : m));
    setrolePlay(allRow);
  };
  const gamingColumns = [
    {
      title: "id",
      dataIndex: "_id",
      key: "_id",
      render: (text, row) => (
        <div>
          <Paragraph
            copyable={{
              text: text,
              tooltips: text,
            }}
            style={{ marginBottom: "0px" }}
          >
            Copy
          </Paragraph>
        </div>
      ),
    },
    {
      title: "Live",
      dataIndex: "live",
      key: "live",
      width: 150,
      render: (text, row) => (
        <Select
          value={text}
          placeholder="live"
          onChange={(value) => {
            changeField("live", value, row);
            saveChanges({ live: value }, row._id, true);
          }}
          style={{ width: "120px" }}
          options={[
            {
              value: "pending",
              label: "Pending",
            },

            {
              value: "processing",
              label: "Processing",
            },
            {
              value: "ready",
              label: "Ready",
            },
          ]}
        />
      ),
    },
    {
      title: "Agent",
      dataIndex: "agent_id",
      key: "agent_id",
      width: 150,
      render: (text, row) => (
        <Select
          value={text}
          placeholder="agent_id"
          onChange={(value) => {
            changeField("agent_id", value, row);
            saveChanges({ agent_id: value }, row._id, true);
          }}
          style={{ width: "120px" }}
          options={[
            {
              value: "PAUL_00",
              label: "Paul",
            },
            {
              value: "RAHE_00",
              label: "Raheel",
            },
            {
              value: "AA_01",
              label: "AA_01",
            },
            {
              value: "NV_08",
              label: "NV_08",
            },
            {
              value: "NB_02",
              label: "NB_02",
            },
            {
              value: "HI_07",
              label: "HI_07",
            },
          ]}
        />
      ),
    },

    {
      title: "Time",
      dataIndex: "time_length",
      key: "time_length",
      render: (text, row) => <b>{text} </b>,
    },
    {
      title: "Instruction",
      dataIndex: "instruction",
      key: "instruction",
      render: (text, row) => (
        <div style={{ maxHeight: "100px", overflowY: "auto" }}>{text} </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "title",
      key: "title",
      render: (text, row) => <b>{text} </b>,
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
      render: (text, row) => <b>{text} </b>,
    },
    {
      title: "Platform",
      dataIndex: "platform",
      key: "platform",
      render: (text, row) => <b>{text} </b>,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text, row) => moment(text).format("MMM Do YYYY"),
    },
    {
      title: "Surface",
      dataIndex: "channelUuid",
      key: "channelUuid",
      render: (text, row) => <b>{text} </b>,
    },
    {
      title: "Link",
      dataIndex: "link",
      key: "link",
      render: (text, row) => (
        <div>
          <Paragraph
            copyable={{
              text: text,
              tooltips: text,
            }}
            style={{ marginBottom: "0px" }}
          >
            Copy
          </Paragraph>
        </div>
      ),
    },
    {
      title: "Stream ID",
      dataIndex: "stream_id",
      key: "stream_id",
      render: (text, row) => (
        <div>
          {" "}
          <Paragraph
            copyable={{
              text: text,
              tooltips: text,
            }}
            style={{ marginBottom: "0px" }}
          >
            Copy
          </Paragraph>
        </div>
      ),
    },
    {
      title: "Stream",
      dataIndex: "stream_url",
      key: "stream_url",
      render: (text, row) => (
        <a href={`https://telemit.com/${row._id}`} target="blank">
          <Button size="small" type="primary">
            Watch
          </Button>
        </a>
      ),
    },

    {
      title: "Coundown",
      dataIndex: "date",
      key: "date",
      render: (text, row) => <CountdownTimer date={text} />,
    },
    {
      title: "Runtime",
      dataIndex: "runtime",
      key: "runtime",
      render: (text, row) => <div>{text}</div>,
    },
    {
      title: "",
      dataIndex: "001",
      key: "001",
      render: (text, row) => (
        <Popconfirm
          title="Are you sure to mark complete this task?"
          onConfirm={() => deleteFile(row._id)}
          okText="Yes"
          cancelText="No"
        >
          <Button size="large" className="red-button">
            D
          </Button>
        </Popconfirm>
      ),
    },
  ];
  const deleteFile = (id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/feed/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          const filtereted = rolePlay.filter((t) => t._id !== id);

          setrolePlay(filtereted);
          message.success("Deleted");
        } else {
          message.error("Something went wrong");
        }
      });
  };
  return (
    <div className="roleplay-main-c">
      <div className="table-top-nav">
        <div
          style={{ justifyContent: "space-between" }}
          className="form-add--theme"
        >
          <div>
            <Select
              value={live}
              placeholder="LIve"
              onChange={(value) => {
                setLive(value);
                setrolePlay();
                setPageNo(1);
                setCallAPI(callAPI + 1);
              }}
              style={{ width: "120px", marginRight: "20px" }}
              options={[
                {
                  value: "pending",
                  label: "Pending",
                },

                {
                  value: "processing",
                  label: "Processing",
                },
                {
                  value: "ready",
                  label: "Ready",
                },
              ]}
            />
            <Select
              value={agent_id}
              placeholder="Agent Id"
              onChange={(value) => {
                setAgentId(value);
                setrolePlay();
                setPageNo(1);
                setCallAPI(callAPI + 1);
              }}
              style={{ width: "120px", marginRight: "20px" }}
              options={[
                {
                  value: "",
                  label: "All",
                },
                {
                  value: "PAUL_00",
                  label: "Paul",
                },
                {
                  value: "RAHE_00",
                  label: "Raheel",
                },
                {
                  value: "AA_01",
                  label: "AA_01",
                },
                {
                  value: "NV_08",
                  label: "NV_08",
                },
                {
                  value: "NB_02",
                  label: "NB_02",
                },
                {
                  value: "HI_07",
                  label: "HI_07",
                },
              ]}
            />
          </div>
        </div>
      </div>
      <div>
        <Table
          className="scrol-tabel-c black-table pagination-white"
          columns={gamingColumns}
          dataSource={rolePlay}
          pagination={{
            pageSize: 15,
            current: pageNo,
            total: totalPages * 15,
            onChange: (v) => {
              setrolePlay();
              setPageNo(v);
            },
            size: "small",
            showSizeChanger: false,
          }}
        />
      </div>
    </div>
  );
}

import React, { useState } from "react";

import { Button, Input, InputNumber, Modal, Select, Spin, message } from "antd";

import { FaPlus } from "react-icons/fa";

import { LoadingOutlined } from "@ant-design/icons";
import "../form.css";
export default function AddInstruction({ callAPI, setCallAPI }) {
  const [loading, setloading] = useState(false);
  const [gamesData, setGamesData] = useState();

  const savePlay = (row) => {
    const obj = {
      stream_id: gamesData.stream_id,
      instruction: gamesData.instruction,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/instruction`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setloading(false);
        if (response && response.isSuccess) {
          message.success("Added");
          setGamesData();
          setCallAPI(callAPI + 1);
        } else if (response.msgError === "Insufficient credits") {
        } else {
          message.error("something went wrong");
        }
      });
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "white",
      }}
      spin
    />
  );
  return (
    <div>
      <Button
        onClick={() => {
          setGamesData({
            ...gamesData,
          });
        }}
        size="small"
        type="primary"
      >
        <FaPlus />
      </Button>
      <Modal
        width={600}
        open={gamesData !== undefined}
        onCancel={() => {
          setGamesData();
        }}
        className="no-f-no-h top-20-x  brand-modal"
      >
        {gamesData ? (
          <div>
            <h5 style={{ textAlign: "center" }}>Add Instruction</h5>

            <div className=" form-pf">
              <div className="game-show-p1">
                <div>
                  <div>
                    <div>
                      <label
                        style={{ margin: "20px 0px" }}
                        for="Stream Id"
                        class="label-pf"
                      >
                        <span class="title-pf">Stream Id *</span>
                        <Input
                          placeholder="Stream Id"
                          onChange={(e) => {
                            setGamesData({
                              ...gamesData,
                              stream_id: e.target.value,
                            });
                          }}
                          value={gamesData.stream_id}
                          style={{ width: "100%" }}
                          class="input-field-pf"
                        />
                      </label>
                      <label
                        style={{ margin: "20px 0px" }}
                        for="instruction"
                        class="label-pf"
                      >
                        <span class="title-pf">instruction *</span>
                        <Input.TextArea
                          autoSize={{ minRows: 2, maxRows: 4 }}
                          placeholder="instruction"
                          onChange={(e) => {
                            setGamesData({
                              ...gamesData,
                              instruction: e.target.value,
                            });
                          }}
                          value={gamesData.instruction}
                          style={{ width: "100%" }}
                          class="input-field-pf"
                        />
                      </label>
                    </div>
                  </div>

                  <div className="space-b-btns">
                    <div />

                    <div className="save-btn-pl">
                      {loading ? (
                        <Spin indicator={antIcon} />
                      ) : gamesData.stream_id && gamesData.stream_id.length ? (
                        <div
                          onClick={() => {
                            setloading(true);
                            savePlay(gamesData);
                          }}
                          className="hover-card-b"
                          style={{
                            maxWidth: "300px",
                          }}
                        >
                          Save
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </Modal>
    </div>
  );
}

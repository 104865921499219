import {
  Input,
  message,
  Modal,
  Spin,
  Table,
  Tag,
  Button as BButton,
  InputNumber,
  Select,
  Button,
} from "antd";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import TagsGroup from "../../components/TagsGroup";

export default function Automation() {
  const [automation, setautomation] = useState();
  const [totalPages, setTotalPages] = useState();

  const [isChange, setIsChange] = useState(false);

  const [pageNo, setPageNo] = useState(1);
  const [callApi, setCallApi] = useState(1);
  const [loadeing, setLoadeing] = useState(true);

  useEffect(() => {
    getautomation();
  }, [pageNo, callApi]);

  const getautomation = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `${
        process.env.REACT_APP_API
      }/automation?page=${pageNo}&limit=15&sort_by=${-1}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setautomation(data.automationList);
          setTotalPages(data.totalPages);
        }

        setLoadeing(false);
        console.log(data);
      });
  };

  const saveChanges = (row, id, save) => {
    if (isChange || save) {
      setIsChange(false);

      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify(row),
      };
      fetch(`${process.env.REACT_APP_API}/automation/${id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data && data.patchedData) {
            message.success("Updated");
          } else {
            message.error("Something went wrong");
          }
        });
    }
  };

  const changeField = (key, val, row) => {
    row[key] = val;
    const allRow = automation.map((m) => (m._id === row._id ? row : m));
    setautomation(allRow);
  };

  const columns = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      width: 150,
      render: (text, row) => (
        <Input
          value={text}
          placeholder="username"
          onChange={(e) => {
            changeField("username", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ username: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Host",
      dataIndex: "host",
      key: "host",
      width: 250,
      render: (text, row) => (
        <Input
          value={text}
          placeholder="host"
          onChange={(e) => {
            changeField("host", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ host: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "status",
      dataIndex: "status",
      key: "status",
      width: 150,
      render: (text, row) => (
        <Select
          value={text}
          placeholder="Status"
          onChange={(value) => {
            changeField("status", value, row);

            saveChanges({ status: value }, row._id, true);
          }}
          className={`${text === "ready" ? " yellow-drop" : ""} ${
            text === "error" ? " red-drop" : ""
          } ${text === "live" ? " green-drop" : ""}`}
          style={{ width: "120px" }}
          options={[
            {
              value: "ready",
              label: "Ready",
            },
            {
              value: "error",
              label: "Error",
            },
            {
              value: "live",
              label: "Live",
            },
          ]}
        />
      ),
    },

    {
      title: "live",
      dataIndex: "live_agent",
      key: "live_agent",
      width: 150,
      render: (text, row) => (
        <Input
          value={text}
          placeholder="Last Used"
          onChange={(e) => {
            changeField("live_agent", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ live_agent: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Used",
      dataIndex: "last_used",
      key: "last_used",
      width: 150,
      render: (text, row) => (
        <Input
          value={text}
          placeholder="Last Used"
          onChange={(e) => {
            changeField("last_used", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ last_used: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Volume",
      dataIndex: "volume",
      key: "volume",
      width: 100,
      render: (text, row) => <b>{text}</b>,
    },
    {
      title: "Config",
      dataIndex: "config",
      key: "config",
      width: 150,
      render: (text, row) => (
        <TagsGroup
          tags={text ? text : []}
          color="#004aa3"
          onTagChange={(v) => {
            changeField("config", v, row);

            saveChanges({ config: v }, row._id, true);
          }}
        />
      ),
    },
  ];
  return (
    <div className="container-c-on">
      <div className="flex-end">
        <Button type="primary" size="small" onClick={() => setCallApi(callApi + 1)}>Reload</Button>
      </div>
      {loadeing ? <Spin /> : null}
      {automation ? (
        <Table
          className="scrol-tabel-c black-table pagination-white"
          pagination={{
            pageSize: 15,
            current: pageNo,
            total: totalPages * 15,
            onChange: (v) => {
              setautomation();
              setPageNo(v);
            },
            size: "small",
            showSizeChanger: false,
          }}
          columns={columns}
          dataSource={automation}
        />
      ) : null}
    </div>
  );
}

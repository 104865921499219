import {
  Input,
  message,
  Modal,
  Spin,
  Table,
  Tag,
  Button,
  InputNumber,
  Select,
  Popconfirm,
} from "antd";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";

import TagsGroup from "../../components/TagsGroup";
import AddInstruction from "./components/AddInstruction";

export default function Instructions() {
  const [callAPI, setCallAPI] = useState(1);
  const [Ins, setIns] = useState();
  const [totalPages, setTotalPages] = useState();

  const [isChange, setIsChange] = useState(false);
  const [stream_id, setStream_id] = useState("");

  const [pageNo, setPageNo] = useState(1);
  const [loadeing, setLoadeing] = useState(true);

  useEffect(() => {
    setLoadeing(true);
    getIns();
  }, [pageNo, callAPI]);

  const getIns = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `${
        process.env.REACT_APP_API
      }/instruction?page=${pageNo}&limit=15&sort_by=${-1}&stream_id=${stream_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setIns(data.instructionList);
          setTotalPages(data.totalPages);
          setTimeout(() => {
            setCallAPI(callAPI + 1);
          }, 15000);
        }

        setLoadeing(false);
        console.log(data);
      });
  };

  const saveChanges = (obj, id, save) => {
    if (isChange || save) {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      };
      fetch(`https://embed.axv.ai/api/instruction/${id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data && data.isSuccess) {
            message.success("Updated");
          } else {
            message.error("Something Went Wrong");
          }
        });
    }
  };


  const deleteInvite = (id) => {
    const filtereted = Ins.filter((t) => t._id !== id);
    setIns(filtereted);
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`${process.env.REACT_APP_API}/instruction/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Deleted");
        } else {
          message.error("Something went wrong");
        }
        console.log(data);
      });
  };

  const changeField = (key, val, row) => {
    row[key] = val;
    const allRow = Ins.map((m) => (m._id === row._id ? row : m));
    setIns(allRow);
  };

  const columns = [
    {
      title: "Stream Id",
      dataIndex: "stream_id",
      key: "stream_id",
      width: 150,
      render: (text, row) => <div>{text}</div>,
    },
    {
      title: "Instruction",
      dataIndex: "instruction",
      key: "instruction",

      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          value={text}
          placeholder="Instruction"
          onChange={(e) => {
            changeField("instruction", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ instruction: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 150,
      render: (text, row) => (
        <Select
          value={text}
          placeholder="status"
          onChange={(value) => {
            changeField("status", value, row);

            saveChanges({ status: value }, row._id, true);
          }}
          style={{ width: "120px" }}
          options={[
            {
              value: "error",
              label: "Error",
            },

            {
              value: "complete",
              label: "Complete",
            },

            {
              value: "pending",
              label: "Pending",
            },
          ]}
        />
      ),
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      render: (text, row) => (
        <TagsGroup
          tags={text ? text : []}
          color="#004aa3"
          onTagChange={(v) => {
            changeField("tags", v, row);
            saveChanges({ tags: v }, row._id, true);
          }}
        />
      ),
    },
    {
      title: "",
      dataIndex: "001",
      key: "001",
      render: (text, row) => (
        <Popconfirm
          title="Are you sure to mark complete this task?"
          onConfirm={() => deleteInvite(row._id)}
          okText="Yes"
          cancelText="No"
        >
          <Button size="large" className="red-button">
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ];
  return (
    <div className="roleplay-main-c">
      <div className="table-top-nav">
        <div
          style={{ justifyContent: "space-between" }}
          className="form-add--theme"
        >
          <AddInstruction callAPI={callAPI} setCallAPI={setCallAPI} />
          <div>
            <Input
              allowClear
              onChange={(e) => {
                setStream_id(e.target.value);

                getIns();
                setPageNo(1);
                setCallAPI(callAPI + 1);
              }}
              value={stream_id}
              placeholder="Stream ID"
            />
          </div>
          <div />
        </div>
      </div>
      {loadeing ? <Spin /> : null}
      {Ins ? (
        <Table
          pagination={{
            pageSize: 15,
            current: pageNo,
            total: totalPages * 15,
            onChange: (v) => {
              setIns();
              setPageNo(v);
            },
            size: "small",
            showSizeChanger: false,
          }}
          className="scrol-tabel-c black-table pagination-white"
          columns={columns}
          dataSource={Ins}
        />
      ) : null}
    </div>
  );
}

import { Button, Modal, Select, Spin, message } from "antd";
import React, { useContext, useState } from "react";
import CountdownTimer from "./CountdownTimer";
import SearchContext from "../../../Context/Search/SearchContext";
import { Typography } from "antd";
const { Paragraph, Text } = Typography;
const { Option } = Select;

export default function StartGame({ row, saveChanges }) {
  const { global, setGlobal } = useContext(SearchContext);

  const [feedRow, setFeedRow] = useState();
  const [loadeing, setLoadeing] = useState(true);
  const [streamLoad, setStreamLoad] = useState(false);

  const getrolePlay = () => {
    const passcode = localStorage.getItem("passcode");

    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/feed/${row._id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          if (data.feedData?.status === "pending") {
            saveChanges(
              { agent_id: passcode, status: "processing" },
              data.feedData?._id,
              true
            );
            setGlobal({
              ...global,
              active: data.feedData,
              tab: "kernal",
              callKernal: global.callKernal ? global.callKernal + 1 : 1,
            });
            // adjustStart(data);
          } else {
            message.warning("already taken");
          }
        }

        setLoadeing(false);
      });
  };
  const adjustStart = (d) => {
    const passcode = localStorage.getItem("passcode");
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `${
        process.env.REACT_APP_API
      }/automation?page=1&limit=15&sort_by=${-1}&status=ready`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (
          data &&
          data.isSuccess &&
          data.automationList &&
          data.automationList.length
        ) {
          let available = data.automationList[0];
          saveChangesAuto(
            { status: "live", live_agent: passcode },
            available._id,
            d
          );
        }

        setLoadeing(false);
      });
  };
  const saveChangesAuto = (row, id, d) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(row),
    };
    fetch(`${process.env.REACT_APP_API}/automation/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.patchedData) {
          const passcode = localStorage.getItem("passcode");
          saveChanges(
            { agent_id: passcode, status: "processing" },
            d.feedData?._id,
            true
          );
          setGlobal({ ...global, active: d.feedData, tab: "kernal" });
        } else {
          message.error("Something went wrong");
        }
      });
  };

  const createLive = async (e) => {
    const obj = {
      roleplay_id: e._id,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "x-api-key": "KqExT5fAmyXkk_SjcT-u0",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/vector/live`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setStreamLoad(false);
        if (response && response.stream_id) {
          setFeedRow({ ...feedRow, liveData: response });
        } else {
          message.error("something went wrong");
        }
      })
      .catch((err) => {});
  };
  return (
    <div>
      <Button onClick={() => getrolePlay()} type="primary">
        Start
      </Button>
      {/* <Modal
        width={600}
        open={feedRow !== undefined}
        onCancel={() => {
          setFeedRow();
        }}
        className="no-f-no-h top-20-x  "
      >
        {feedRow ? (
          <div>
            <h5>Start Game</h5>
            <table className="table-my-form">
              <tr>
                <td>
                  <b>username</b>
                </td>
                <td>
                  <b>{feedRow.username}</b>
                </td>
              </tr>
              <tr>
                <td>
                  <b>game</b>
                </td>
                <td>
                  <b>{feedRow.game}</b>
                </td>
              </tr>
              <tr>
                <td>
                  <b>date</b>
                </td>
                <td>
                  <b>
                    <CountdownTimer date={feedRow.date} />
                  </b>
                </td>
              </tr>
              <tr>
                <td>
                  <b>Status</b>
                </td>
                <td>
                  <Select
                    placeholder="status"
                    style={{ width: 130 }} // Set the desired width in pixels
                    value={feedRow.status}
                    onChange={(value) => {
                      setFeedRow({ ...feedRow, status: value });

                      saveChanges({ status: value }, row._id, true);
                    }}
                  >
                    <Option value={"pending"}>Pending</Option>
                    <Option value={"downloading"}>Downloading</Option>
                    <Option value={"complete"}>Complete</Option>
                    <Option value={"error"}>Error</Option>
                  </Select>
                </td>
              </tr>
            </table>
            {feedRow.liveData ? (
              <div>
                <h6>Streak ID</h6>
                <Paragraph
                  copyable={{
                    tooltips: ["Copy Text", "Copied!!"],
                  }}
                >
                  {feedRow.liveData.stream_id}
                </Paragraph>
                <div className="flex-end">
                  <a href={feedRow.liveData.stream_url} target="blank">
                    <Button type="primary">Stream URL</Button>
                  </a>
                </div>
              </div>
            ) : (
              <div className="flex-end">
                {streamLoad ? (
                  <Spin />
                ) : (
                  <Button
                    type="primary"
                    onClick={() => {
                      setStreamLoad(true);
                      createLive(feedRow);
                    }}
                  >
                    Create Live
                  </Button>
                )}
              </div>
            )}
          </div>
        ) : null}
      </Modal> */}
    </div>
  );
}

import React, { useState } from "react";

import { Button, Input, InputNumber, Modal, Select, Spin, message } from "antd";

import { FaPlus } from "react-icons/fa";

import { LoadingOutlined } from "@ant-design/icons";
import "../form.css";
export default function AddOrganization({ callAPI, setCallAPI }) {
  const [loading, setloading] = useState(false);
  const [gamesData, setGamesData] = useState();

  const savePlay = (row) => {
    const obj = {
      root_email: gamesData.root_email,
      business_name: gamesData.business_name,
      subscription: gamesData.subscription,
      platform_credits: gamesData.platform_credits,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/organization`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setloading(false);
        if (response && response.isSuccess) {
          message.success("Added");
          setGamesData();
          setCallAPI(callAPI + 1);
        } else if (response.msgError === "Insufficient credits") {
        } else {
          message.error("something went wrong");
        }
      });
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "white",
      }}
      spin
    />
  );
  return (
    <div>
      <Button
        onClick={() => {
          setGamesData({
            ...gamesData,
          });
        }}
        size="small"
        type="primary"
      >
        <FaPlus />
      </Button>
      <Modal
        width={600}
        open={gamesData !== undefined}
        onCancel={() => {
          setGamesData();
        }}
        className="no-f-no-h top-20-x  brand-modal"
      >
        {gamesData ? (
          <div>
            <h5 style={{ textAlign: "center" }}>Add Organization</h5>

            <div className=" form-pf">
              <div className="game-show-p1">
                <div>
                  <div>
                    <div>
                      <label
                        style={{ margin: "20px 0px" }}
                        for="Business Name"
                        class="label-pf"
                      >
                        <span class="title-pf">Business Name *</span>
                        <Input
                          placeholder="Name"
                          onChange={(e) => {
                            setGamesData({
                              ...gamesData,
                              business_name: e.target.value,
                            });
                          }}
                          value={gamesData.business_name}
                          style={{ width: "100%" }}
                          class="input-field-pf"
                        />
                      </label>

                      <div
                        className="together-fields-c"
                        style={{ margin: "20px 0px" }}
                      >
                        <label
                          style={{ width: "100%", marginRight: "10px" }}
                          for="Root Email"
                          class="label-pf"
                        >
                          <span class="title-pf"> Root Email *</span>

                          <Input
                            placeholder=" Root Email"
                            onChange={(e) => {
                              setGamesData({
                                ...gamesData,
                                root_email: e.target.value,
                              });
                            }}
                            class="input-field-pf"
                            value={gamesData.root_email}
                            size="large"
                          />
                        </label>
                      </div>
                      <label
                        style={{ margin: "20px 0px" }}
                        for="Platform Credits"
                        class="label-pf"
                      >
                        <span class="title-pf">Platform Credits *</span>
                        <InputNumber
                          onChange={(e) => {
                            setGamesData({
                              ...gamesData,
                              platform_credits: e,
                            });
                          }}
                          value={gamesData.platform_credits}
                          style={{ width: "100%" }}
                          class="input-field-pf"
                        />
                      </label>
                      <div>
                        <label
                          style={{ margin: "20px 0px" }}
                          for="subscription"
                          class="label-pf"
                        >
                          <span class="title-pf">Subscription *</span>
                          <Select
                            onChange={(e) => {
                              setGamesData({
                                ...gamesData,
                                subscription: e,
                              });
                            }}
                            value={gamesData.subscription}
                            placeholder="subscription"
                            options={[
                              {
                                value: "business",
                                label: "Business",
                              },

                              {
                                value: "community",
                                label: "Community",
                              },

                              {
                                value: "individual",
                                label: "Individual",
                              },
                              {
                                value: "startup",
                                label: "Startup",
                              },

                              {
                                value: "pro",
                                label: "Pro",
                              },
                              {
                                value: "restricted",
                                label: "Restricted",
                              },
                            ]}
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="space-b-btns">
                    <div />

                    <div className="save-btn-pl">
                      {loading ? (
                        <Spin indicator={antIcon} />
                      ) : gamesData.business_name &&
                        gamesData.business_name.length &&
                        gamesData.root_email &&
                        gamesData.root_email.length &&
                        gamesData.subscription ? (
                        <div
                          onClick={() => {
                            setloading(true);
                            savePlay(gamesData);
                          }}
                          className="hover-card-b"
                          style={{
                            maxWidth: "300px",
                          }}
                        >
                          Save
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </Modal>
    </div>
  );
}

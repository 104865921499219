import {
  Input,
  message,
  Image,
  Modal,
  Spin,
  Table,
  Tag,
  Button,
  InputNumber,
  Select,
  Popconfirm,
  Row,
  Col,
} from "antd";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { MdClose } from "react-icons/md";

export default function ViewScreenshoots({ row }) {
  const [list, setlist] = useState();
  const [totalPages, setTotalPages] = useState();
  const [show, setShow] = useState();
  const [isChange, setIsChange] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [loadeing, setLoadeing] = useState(false);

  useEffect(() => {
    if (show) {
      getlist();
    }
  }, [show]);

  const getlist = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `${
        process.env.REACT_APP_API
      }/vector?page=1&limit=40&sort_by=${-1}&stream_id=${row._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setlist(data.vectorList);
          setTotalPages(data.totalPages);
        }

        setLoadeing(false);
      });
  };

  const deleteFile = (id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`${process.env.REACT_APP_API}/vector/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          const filtereted = list.filter((t) => t._id !== id);
          setlist(filtereted);
          message.success("Deleted");
        } else {
          message.error("Something went wrong");
        }
        console.log(data);
      });
  };

  const DynamicTimeDisplay = (d) => {
    let hours = Math.floor(d / 3600);
    hours = hours < 10 ? "0" + hours : hours;
    let minutes = Math.floor((d % 3600) / 60);
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let secondsRemaining = Math.floor(d % 60);
    let seconds =
      secondsRemaining < 10 ? "0" + secondsRemaining : secondsRemaining;

    return (
      <div className="flex-end-bvh">
        {hours}:{minutes}:{seconds}
      </div>
    );
  };
  return (
    <div>
      {loadeing ? (
        <Spin style={{ color: "white" }} />
      ) : (
        <Button
          onClick={() => {
            setShow(row);
            setLoadeing(true);
          }}
          className="yellow-button"
          size="large"
          type="primary"
        >
          V
        </Button>
      )}
      <Modal
        width={1000}
        open={list !== undefined}
        onCancel={() => {
          setShow();
          setlist();
        }}
        className="no-f-no-h top-20-x  brand-modal"
      >
        <h4>{row.title}</h4>
        {list && list.length ? (
          <Row>
            {list.map((l, li) => {
              return (
                <Col
                  lg={{ span: 6 }}
                  md={{ span: 8 }}
                  sm={{ span: 12 }}
                  xs={{ span: 24 }}
                  style={{ padding: "10px" }}
                >
                  <div className="image-card-ss">
                    <Popconfirm
                      title="Delete this asset"
                      onConfirm={() => deleteFile(l._id)}
                      // onCancel={cancel}
                      okText="Yes"
                      cancelText="No"
                      placement="right"
                      trigger={"click"}
                    >
                      <b className="show-hover">
                        <MdClose
                          style={{
                            position: "relative",
                            zIndex: 120,
                          }}
                          className="circular-icon hover"
                        />
                      </b>
                    </Popconfirm>
                    <Image className="thumbnail-img" src={l.thumbnail} alt="" />
                    {l.timestamp ? (
                      <div className="time-stamp-img-ss">
                        {DynamicTimeDisplay(l.timestamp)}
                      </div>
                    ) : null}
                    <div className="content-bx-ss-img">{l.content}</div>
                  </div>
                </Col>
              );
            })}
          </Row>
        ) : (
          <h1>No Data Added</h1>
        )}
      </Modal>
    </div>
  );
}

import React, { useState } from "react";

import { Button, Input, InputNumber, Modal, Select, Spin, message } from "antd";

import { FaPlus } from "react-icons/fa";

import { LoadingOutlined } from "@ant-design/icons";
import "../form.css";
export default function AddDocument({ callAPI, setCallAPI }) {
  const [loading, setloading] = useState(false);
  const [documenData, setdocumenData] = useState();

  const savePlay = (row) => {
    const obj = {
      user_id: documenData.user_id,
      title: documenData.title,
      description: documenData.description,
      feed_type: "document",
      balance: 1,
      time_length: 1,

    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/feed`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setloading(false);
        if (response && response.isSuccess) {
          message.success("Added");
          setdocumenData();
          setCallAPI(callAPI + 1);
        } else if (response.msgError === "Insufficient credits") {
        } else {
          message.error("something went wrong");
        }
      });
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "white",
      }}
      spin
    />
  );
  return (
    <div>
      <Button
        onClick={() => {
          setdocumenData({
            ...documenData,
          });
        }}
        size="small"
        type="primary"
      >
        <FaPlus />
      </Button>
      <Modal
        width={600}
        open={documenData !== undefined}
        onCancel={() => {
          setdocumenData();
        }}
        className="no-f-no-h top-20-x  brand-modal"
      >
        {documenData ? (
          <div>
            <h5 style={{ textAlign: "center" }}>Add Document</h5>

            <div className=" form-pf">
              <div className="game-show-p1">
                <div>
                  <div>
                    <div>
                      <label
                        style={{ margin: "20px 0px" }}
                        for="User Id"
                        class="label-pf"
                      >
                        <span class="title-pf">User Id *</span>
                        <Input
                          placeholder="User Id"
                          onChange={(e) => {
                            setdocumenData({
                              ...documenData,
                              user_id: e.target.value,
                            });
                          }}
                          value={documenData.user_id}
                          style={{ width: "100%" }}
                          class="input-field-pf"
                        />
                      </label>
                      <label
                        style={{ margin: "20px 0px" }}
                        for="title"
                        class="label-pf"
                      >
                        <span class="title-pf">Title *</span>
                        <Input.TextArea
                          autoSize={{ minRows: 2, maxRows: 4 }}
                          placeholder="title"
                          onChange={(e) => {
                            setdocumenData({
                              ...documenData,
                              title: e.target.value,
                            });
                          }}
                          value={documenData.title}
                          style={{ width: "100%" }}
                          class="input-field-pf"
                        />
                      </label>
                      <label
                        style={{ margin: "20px 0px" }}
                        for="description"
                        class="label-pf"
                      >
                        <span class="title-pf">Description </span>
                        <Input.TextArea
                          autoSize={{ minRows: 2, maxRows: 4 }}
                          placeholder="Description"
                          onChange={(e) => {
                            setdocumenData({
                              ...documenData,
                              description: e.target.value,
                            });
                          }}
                          value={documenData.description}
                          style={{ width: "100%" }}
                          class="input-field-pf"
                        />
                      </label>
                    </div>
                  </div>

                  <div className="space-b-btns">
                    <div />

                    <div className="save-btn-pl">
                      {loading ? (
                        <Spin indicator={antIcon} />
                      ) : documenData.user_id &&
                        documenData.user_id.length &&
                        documenData.title &&
                        documenData.title.length ? (
                        <div
                          onClick={() => {
                            setloading(true);
                            savePlay(documenData);
                          }}
                          className="hover-card-b"
                          style={{
                            maxWidth: "300px",
                          }}
                        >
                          Save
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </Modal>
    </div>
  );
}

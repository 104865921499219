import {
  Input,
  message,
  Modal,
  Spin,
  Table,
  Tag,
  Button as BButton,
  InputNumber,
  Select,
  Tooltip,
  Typography,
  Button,
  Popconfirm,
} from "antd";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import CountdownTimer from "./components/CountdownTimer";
import StartGame from "./components/StartGame";
import SearchContext from "../../Context/Search/SearchContext";
import moment from "moment";
import UploadScreenShoot from "./components/UploadScreenShoot";
import GreenTick from "../../components/GreenTick";
import ViewScreenshoots from "./components/ViewScreenshoots";
const { Paragraph, Text } = Typography;
const { Option } = Select;
export default function Vision() {
  const { global, setGlobal } = useContext(SearchContext);

  const [callAPI, setCallAPI] = useState(1);
  const [rolePlay, setrolePlay] = useState();
  const [totalPages, setTotalPages] = useState();
  const [status, setStatus] = useState("vision");
  const [agent_id, setAgent_id] = useState("");
  const [tags, setTags] = useState("");
  const [isChange, setIsChange] = useState(false);
  const [feed_type, setFeed_type] = useState("public_game");

  const [pageNo, setPageNo] = useState(1);
  const [loadeing, setLoadeing] = useState(true);

  useEffect(() => {
    if (rolePlay === undefined || callAPI > 1) {
      // setLoadeing(true);

      getrolePlay();
    }
  }, [pageNo, callAPI]);

  const getrolePlay = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `${
        process.env.REACT_APP_API
      }/feed?page=${pageNo}&limit=15&sort_by=${-1}&status=${status}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setrolePlay(data.feedList);
          setTotalPages(data.totalPages);
        }

        setLoadeing(false);
      });
  };

  const saveChanges = (row, id, save) => {
    if (isChange || save) {
      setIsChange(false);

      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify(row),
      };
      fetch(`${process.env.REACT_APP_API}/feed/${id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data && data.isPatched) {
            message.success("Updated");
            const allRow = rolePlay.map((m) =>
              m._id === id ? data.patchedData : m
            );
            setrolePlay(allRow);
          } else {
            message.error("Something went wrong");
          }
        });
    }
  };

  const changeField = (key, val, row) => {
    row[key] = val;
    const allRow = rolePlay.map((m) => (m._id === row._id ? row : m));
    setrolePlay(allRow);
  };

  const gamingColumns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text, row) => <b>{text} </b>,
    },
    {
      title: "Game Link",
      dataIndex: "link",
      key: "link",
      render: (text, row) => (
        <div>
          {" "}
          <Paragraph
            copyable={{
              text: text,
              tooltips: text,
            }}
            style={{ marginBottom: "0px" }}
          >
            Copy
          </Paragraph>
        </div>
      ),
    },
    {
      title: "Video File Url",
      dataIndex: "file_url",
      key: "file_url",
      render: (text, row) => (
        <div>
          {" "}
          <Paragraph
            copyable={{
              text: text,
              tooltips: text,
            }}
            style={{ marginBottom: "0px" }}
          >
            Copy
          </Paragraph>
        </div>
      ),
    },
    {
      title: "Upload",
      dataIndex: "001",
      key: "001",
      width: 120,
      render: (text, row) => <UploadScreenShoot row={row} />,
    },
    {
      title: "Screen Shoots",
      dataIndex: "002",
      key: "002",
      width: 140,
      

      render: (text, row) => (
        <ViewScreenshoots row={row}/>
      ),
    },
    {
      title: "",
      dataIndex: "003",
      key: "003",

      render: (text, row) =>
        row.status === "complete" ? (
          <GreenTick width={"25px"} height={"25px"} />
        ) : (
          <Popconfirm
            title="All Images Added?"
            onConfirm={() => saveChanges({ status: "complete" }, row._id, true)}
            okText="Yes"
            cancelText="No"
          >
            <Button size="large" className="green-button" type="primary">
              Complete
            </Button>
          </Popconfirm>
        ),
    },
  ];

  return (
    <div className="roleplay-main-c">
      <div style={{ justifyContent: "center" }} className="table-top-nav">
        <div className="form-add--theme"></div>
      </div>

      {loadeing ? <Spin /> : null}
      {rolePlay ? (
        <Table
          className="scrol-tabel-c black-table pagination-white"
          pagination={{
            pageSize: 15,
            current: pageNo,
            total: totalPages * 15,
            onChange: (v) => {
              setrolePlay();
              setPageNo(v);
            },
            size: "small",
            showSizeChanger: false,
          }}
          columns={gamingColumns}
          dataSource={rolePlay}
        />
      ) : null}
    </div>
  );
}

import {
  Input,
  message,
  Modal,
  Spin,
  Table,
  Tag,
  Button as BButton,
  InputNumber,
  Select,
  Tooltip,
  Typography,
  Button,
  Popconfirm,
} from "antd";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";

import moment from "moment";
import AddFeed from "./components/AddFeed";
import { MdClose } from "react-icons/md";
import TagsGroup from "../../components/TagsGroup";
import ViewScreenshoots from "./components/ViewScreenshoots";
const { Paragraph, Text } = Typography;
const { Option } = Select;
export default function FeedTable() {
  const [callAPI, setCallAPI] = useState(1);
  const [rolePlay, setrolePlay] = useState();
  const [totalPages, setTotalPages] = useState();
  const [status, setStatus] = useState("pending");
  const [access, setAccess] = useState("");
  const [search, setSearch] = useState("");

  const [isChange, setIsChange] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [loadeing, setLoadeing] = useState(true);

  useEffect(() => {
    if (search.length === 0 && (rolePlay === undefined || callAPI > 1)) {
      // setLoadeing(true);

      getrolePlay();
    }
  }, [pageNo, callAPI]);

  const getrolePlay = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `${
        process.env.REACT_APP_API
      }/feed?page=${pageNo}&limit=15&sort_by=${-1}&status=${status}&access=${access}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setrolePlay(data.feedList);
          setTotalPages(data.totalPages);
          setTimeout(() => {
            setCallAPI(callAPI + 1);
          }, 15000);
        }

        setLoadeing(false);
      });
  };
  const getrolePlaySearch = (s) => {
    const obj = {
      query: s,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`${process.env.REACT_APP_API}/feed/search`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setrolePlay(data);
        }
      });
  };
  const saveChanges = (row, id, save) => {
    if (isChange || save) {
      setIsChange(false);

      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify(row),
      };
      fetch(`${process.env.REACT_APP_API}/feed/${id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data && data.isPatched) {
            message.success("Updated");
          } else {
            message.error("Something went wrong");
          }
        });
    }
  };

  const changeField = (key, val, row) => {
    row[key] = val;
    const allRow = rolePlay.map((m) => (m._id === row._id ? row : m));
    setrolePlay(allRow);
  };
  const deleteFile = (id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/feed/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          const filtereted = rolePlay.filter((t) => t._id !== id);

          setrolePlay(filtereted);
          message.success("Deleted");
        } else {
          message.error("Something went wrong");
        }
      });
  };

  const saveCrawl = (row) => {
    const obj = {
      file_url: row.file_url,
      id: row._id,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/vector/crawl`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
          message.success("Success");
          changeField("crawlLoading", false, row);
        } else {
          message.error("something went wrong");
        }
      });
  };
  const gamingColumns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text, row) => moment(text).format("MMM Do YYYY"),
    },
    {
      title: "Name",
      dataIndex: "title",
      key: "title",
      width: 200,

      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          value={text}
          placeholder="Name"
          onChange={(e) => {
            changeField("title", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ title: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },

    {
      title: "File url",
      dataIndex: "file_url",
      key: "file_url",
      width: 200,
      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          value={text}
          placeholder="File url"
          onChange={(e) => {
            changeField("file_url", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ file_url: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      width: 200,
      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          value={text}
          placeholder="Comment"
          onChange={(e) => {
            changeField("comment", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ comment: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Instruction",
      dataIndex: "instruction",
      key: "instruction",
      width: 200,
      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          value={text}
          placeholder="Instruction"
          onChange={(e) => {
            changeField("instruction", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ instruction: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },

    {
      title: "Link",
      dataIndex: "link",
      key: "link",
      width: 200,
      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          value={text}
          placeholder="Link"
          onChange={(e) => {
            changeField("link", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ link: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "UserID",
      dataIndex: "user_id",
      key: "user_id",
      width: 200,
      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          value={text}
          placeholder="user_id"
          onChange={(e) => {
            changeField("user_id", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ user_id: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 200,
      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          value={text}
          placeholder="description"
          onChange={(e) => {
            changeField("description", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ description: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },

    {
      title: "Crawler",
      dataIndex: "crawler",
      key: "crawler",
      width: 200,
      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          value={text}
          placeholder="crawler"
          onChange={(e) => {
            changeField("crawler", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ crawler: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },


    {
      title: "Thumbnail",
      dataIndex: "thumbnail",
      key: "thumbnail",
      width: 200,
      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          value={text}
          placeholder="thumbnail"
          onChange={(e) => {
            changeField("thumbnail", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ thumbnail: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "id",
      dataIndex: "_id",
      key: "_id",
      render: (text, row) => (
        <div>
          <Paragraph
            copyable={{
              text: text,
              tooltips: text,
            }}
            style={{ marginBottom: "0px" }}
          >
            Copy
          </Paragraph>
        </div>
      ),
    },
    {
      title: "Stream ID",
      dataIndex: "stream_id",
      key: "stream_id",
      render: (text, row) => (
        <div>
          <Paragraph
            copyable={{
              text: text,
              tooltips: text,
            }}
            style={{ marginBottom: "0px" }}
          >
            Copy
          </Paragraph>
        </div>
      ),
    },
    
    {
      title: "status",
      dataIndex: "status",
      key: "status",
      width: 150,
      render: (text, row) => (
        <Select
          value={text}
          placeholder="Status"
          onChange={(value) => {
            changeField("status", value, row);

            saveChanges({ status: value }, row._id, true);
          }}
          style={{ width: "120px" }}
          options={[
            {
              value: "pending",
              label: "Pending",
            },
            {
              value: "vision",
              label: "Vision",
            },
            {
              value: "processing",
              label: "Processing",
            },
            {
              value: "watch",
              label: "Watch",
            },
            {
              value: "complete",
              label: "Complete",
            },
            {
              value: "review",
              label: "Review",
            },
            {
              value: "payment",
              label: "Payment",
            },
            {
              value: "error",
              label: "Error",
            },
            {
              value: "demo",
              label: "Demo",
            },
          ]}
        />
      ),
    },
    {
      title: "Screen Shoots",
      dataIndex: "002",
      key: "002",

      render: (text, row) =>
        row.crawlLoading ? (
          <Spin />
        ) : (
          <Button
            onClick={() => {
              changeField("crawlLoading", true, row);
              saveCrawl(row);
            }}
            type="primary"
          >
            Cra
          </Button>
        ),
    },
    {
      title: "Screen Shoots",
      dataIndex: "002",
      key: "002",

      render: (text, row) => <ViewScreenshoots row={row} />,
    },
    {
      title: "Watch",
      dataIndex: "watch",
      key: "watch",
      render: (text, row) => (
        <a href={`https://link.telemit/${row._id}`} target="blank">
          <Button size="large" type="primary">
            W
          </Button>
        </a>
      ),
    },

    {
      title: "",
      dataIndex: "001",
      key: "001",
      render: (text, row) => (
        <Popconfirm
          title="Are you sure to mark complete this task?"
          onConfirm={() => deleteFile(row._id)}
          okText="Yes"
          cancelText="No"
        >
          <Button size="large" className="red-button">
            D
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <div className="roleplay-main-c">
      <div className="table-top-nav">
        <div
          style={{ justifyContent: "space-between" }}
          className="form-add--theme"
        >
          <AddFeed callAPI={callAPI} setCallAPI={setCallAPI} />
          <div>
            <Input
              onChange={(e) => {
                setSearch(e.target.value);
                if (e.target.value.length) {
                  setrolePlay();
                  getrolePlaySearch(e.target.value);
                } else {
                  setrolePlay();
                  setPageNo(1);
                  setCallAPI(callAPI + 1);
                }
              }}
              value={search}
              placeholder="Search Title"
            />
          </div>
          <div>
            <Select
              value={access}
              placeholder="access"
              onChange={(value) => {
                setAccess(value);
                setrolePlay();
                setPageNo(1);
                setCallAPI(callAPI + 1);
              }}
              style={{ width: "120px", marginRight: "20px" }}
              options={[
                {
                  value: "",
                  label: "Access All",
                },
                {
                  value: "public",
                  label: "Public",
                },
                {
                  value: "private",
                  label: "Private",
                },
              ]}
            />
            <Select
              value={status}
              placeholder="Status"
              onChange={(value) => {
                setStatus(value);
                setrolePlay();
                setPageNo(1);
                setCallAPI(callAPI + 1);
              }}
              style={{ width: "120px" }}
              options={[
                {
                  value: "",
                  label: "Status All",
                },
                {
                  value: "vision",
                  label: "Vision",
                },
                {
                  value: "pending",
                  label: "Pending",
                },
                {
                  value: "processing",
                  label: "Processing",
                },
                {
                  value: "watch",
                  label: "Watch",
                },
                {
                  value: "complete",
                  label: "Complete",
                },
                {
                  value: "review",
                  label: "Review",
                },
                {
                  value: "payment",
                  label: "Payment",
                },
                {
                  value: "error",
                  label: "Error",
                },
              ]}
            />
          </div>
        </div>
      </div>

      {loadeing ? <Spin /> : null}
      {rolePlay ? (
        <Table
          className="scrol-tabel-c black-table pagination-white"
          pagination={{
            pageSize: 15,
            current: pageNo,
            total: totalPages * 15,
            onChange: (v) => {
              setrolePlay();
              setPageNo(v);
            },
            size: "small",
            showSizeChanger: false,
          }}
          columns={gamingColumns}
          dataSource={rolePlay}
        />
      ) : null}
    </div>
  );
}

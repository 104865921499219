import {
  Input,
  message,
  Modal,
  Spin,
  Table,
  Tag,
  Button,
  InputNumber,
  Select,
  Popconfirm,
} from "antd";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";

import TagsGroup from "../../components/TagsGroup";
import AddInstruction from "./components/AddInstruction";
import AddDocument from "./components/AddDocument";
import DblocksTable from "./components/DblocksTable";

export default function Documents() {
  const [callAPI, setCallAPI] = useState(1);
  const [Ins, setIns] = useState();
  const [totalPages, setTotalPages] = useState();

  const [isChange, setIsChange] = useState(false);
  const [user_id, setuser_id] = useState("");

  const [pageNo, setPageNo] = useState(1);
  const [loadeing, setLoadeing] = useState(true);

  useEffect(() => {
    setLoadeing(true);
    getIns();
  }, [pageNo, callAPI]);

  const getIns = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `${
        process.env.REACT_APP_API
      }/feed?page=${pageNo}&limit=10&sort_by=${-1}&user_id=${user_id}&feed_type=document`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setIns(data.feedList);
          setTotalPages(data.totalPages);
          // setTimeout(() => {
          //   setCallAPI(callAPI + 1);
          // }, 15000);
        }

        setLoadeing(false);
        console.log(data);
      });
  };

  const saveChanges = (obj, id, save) => {
    if (isChange || save) {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      };
      fetch(`https://embed.axv.ai/api/feed/${id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data && data.isPatched) {
            message.success("Updated");
          } else {
            message.error("Something Went Wrong");
          }
        });
    }
  };

  const deleteInvite = (id) => {
    const filtereted = Ins.filter((t) => t._id !== id);
    setIns(filtereted);
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`${process.env.REACT_APP_API}/feed/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Deleted");
        } else {
          message.error("Something went wrong");
        }
        console.log(data);
      });
  };

  const changeField = (key, val, row) => {
    row[key] = val;
    const allRow = Ins.map((m) => (m._id === row._id ? row : m));
    setIns(allRow);
  };

  const columns = [
    {
      title: "User Id",
      dataIndex: "user_id",
      key: "user_id",
      width: 150,
      render: (text, row) => <div>{text}</div>,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",

      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          value={text}
          placeholder="title"
          onChange={(e) => {
            changeField("title", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ title: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",

      render: (text, row) => (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          value={text}
          placeholder="description"
          onChange={(e) => {
            changeField("description", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ description: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",

      render: (text, row) => <DblocksTable tRow={row}/>,
    },
    {
      title: "",
      dataIndex: "001",
      key: "001",
      width: 100,
      render: (text, row) => (
        <Popconfirm
          title="Are you sure to mark complete this task?"
          onConfirm={() => deleteInvite(row._id)}
          okText="Yes"
          cancelText="No"
        >
          <Button  className="red-button">
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ];
  return (
    <div className="roleplay-main-c">
      <div className="table-top-nav">
        <div
          style={{ justifyContent: "space-between" }}
          className="form-add--theme"
        >
          <AddDocument callAPI={callAPI} setCallAPI={setCallAPI} />
          <div>
            <Input
              allowClear
              onChange={(e) => {
                setuser_id(e.target.value);

                getIns();
                setPageNo(1);
                setCallAPI(callAPI + 1);
              }}
              value={user_id}
              placeholder="User Id"
            />
          </div>
          <div />
        </div>
      </div>
      {loadeing ? <Spin /> : null}
      {Ins ? (
        <Table
          pagination={{
            pageSize: 10,
            current: pageNo,
            total: totalPages * 10,
            onChange: (v) => {
              setIns();
              setPageNo(v);
            },
            size: "small",
            showSizeChanger: false,
          }}
          className="scrol-tabel-c black-table pagination-white"
          columns={columns}
          dataSource={Ins}
        />
      ) : null}
    </div>
  );
}

import React, { useState } from "react";

import { Button, Input, InputNumber, Modal, Select, Spin, message } from "antd";

import { FaPlus } from "react-icons/fa";

import { LoadingOutlined } from "@ant-design/icons";
import "../form.css";
export default function AddDblocks({
  callAPI,
  setCallAPI,
  tRow,
  dBlock,
  setdBlock,
}) {
  const [loading, setloading] = useState(false);

  const savePlay = (row) => {
    const obj = {
      parameter: dBlock.parameter,
      document_id: tRow._id,
      block_type: "document",
      status: "pending",
      view: "open",

    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/block`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setloading(false);
        if (response && response.isSuccess) {
          message.success("Added");
          setdBlock();
          setCallAPI(callAPI + 1);
        } else if (response.msgError === "Insufficient credits") {
        } else {
          message.error("something went wrong");
        }
      });
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "white",
      }}
      spin
    />
  );
  return (
    <div>
      <Button
        onClick={() => {
          setdBlock({
            ...dBlock,
          });
        }}
        size="small"
        type="primary"
      >
        <FaPlus />
      </Button>

      {dBlock ? (
        <div>
          <h5 style={{ textAlign: "center" }}>Add Block</h5>

          <div className=" form-pf">
            <div className="game-show-p1">
              <div>
                <div>
                  <div>
                    <label
                      style={{ margin: "20px 0px" }}
                      for="parameter"
                      class="label-pf"
                    >
                      <span class="title-pf">Parameter *</span>
                      <Input.TextArea
                        autoSize={{ minRows: 2, maxRows: 4 }}
                        placeholder="parameter"
                        onChange={(e) => {
                          setdBlock({
                            ...dBlock,
                            parameter: e.target.value,
                          });
                        }}
                        value={dBlock.parameter}
                        style={{ width: "100%" }}
                        class="input-field-pf"
                      />
                    </label>
                  </div>
                </div>

                <div className="space-b-btns">
                  <div />

                  <div className="save-btn-pl">
                    {loading ? (
                      <Spin indicator={antIcon} />
                    ) : dBlock.parameter && dBlock.parameter.length ? (
                      <div
                        onClick={() => {
                          setloading(true);
                          savePlay(dBlock);
                        }}
                        className="hover-card-b"
                        style={{
                          maxWidth: "300px",
                        }}
                      >
                        Save
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

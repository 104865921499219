import React, { useEffect, useState } from "react";
import { Form, Input, Button, Switch, message, Spin } from "antd";

const Titan = () => {
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState();
  useEffect(() => {
    getrolePlay();
  }, []);
  const getrolePlay = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`${process.env.REACT_APP_API}/guide?page=1&limit=15`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data && data.guideList && data.guideList.length) {
          setFormData(data.guideList[0]);
        }
      });
  };

  const handleChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleSubmit = () => {
    // You can post formData to your API here
    console.log("Form Data:", formData);
    saveChanges(formData  , formData._id );
    setEditMode(false)
    // Reset form after submission
    // setFormData({
    //   server_status: "",
    //   w_time: 0,
    //   server_flow: "",
    //   live_data: false,
    //   live_url: "",
    // });
  };
  const connect = (obj) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/guide`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);

        if (data.isSuccess) {
          message.success("Added");
        } else {
          message.error("Error");
          setEditMode(true)

        }
      });
  };
  const saveChanges = (row, id, save) => {
    delete row._id
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(row),
    };
    fetch(`${process.env.REACT_APP_API}/guide/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Updated");
        } else {
          message.error("Something went wrong");
        }
      });
  };
  return (
    <div className="tital-form-c">
      {formData ? (
        <Form layout="vertical">
          <Form.Item label="Server Status">
            <Input
              disabled={!editMode}
              value={formData.server_status}
              onChange={(e) => handleChange("server_status", e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Wait Time">
            <Input
              disabled={!editMode}
              type="number"
              value={formData.w_time}
              onChange={(e) => handleChange("w_time", parseInt(e.target.value))}
            />
          </Form.Item>
          <Form.Item label="Server Flow">
            <Input
              value={formData.server_flow}
              disabled={!editMode}
              onChange={(e) => handleChange("server_flow", e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Live Data">
            <Switch
              checked={formData.live_data}
              disabled={!editMode}
              onChange={(checked) => handleChange("live_data", checked)}
            />
          </Form.Item>
          <Form.Item label="Live URL">
            <Input
              value={formData.live_url}
              disabled={!editMode}
              onChange={(e) => handleChange("live_url", e.target.value)}
            />
          </Form.Item>
          <Form.Item className="flex-end-c">
            {editMode ? (
              <Button type="primary" onClick={handleSubmit}>
                Update
              </Button>
            ) : (
              <Button type="primary" onClick={() => setEditMode(true)}>
                Enable Edit
              </Button>
            )}
          </Form.Item>
        </Form>
      ) : loading ? (
        <Spin />
      ) : null}
    </div>
  );
};

export default Titan;
